import { api } from '@/lib/api';

const initialState = function() {
  return {
    uri: '',
    name: '',
    coworkerNumber: '',
    lawyers: '',
    email: '',
    specialities: [],
    interventions: [],
    customTexts: { label: 'label' },
    logo: '',
    address: {
      addressLine1: '',
      postalCode: '',
      city: '',
      phoneNumber: '',
      longitude: '',
      latitude: '',
    },
    coworkers: [],
  };
};

const getters = {
  getState: state => state,
  getName: state => state.name,
  getCoworkerNumber: state => state.coworkerNumber,
  getLawyers: state => state.lawyers,
  getEmail: state => state.email,
  getAddress: state => state.address,
  getSpecialities: state => state.specialities,
  getInterventions: state => state.interventions,
  getCustomTexts: state => {
    if (typeof state.customTexts === 'undefined') {
      state.customTexts = { label: 'label' };
    }
    if (state.customTexts.length == 0) {
      state.customTexts = { label: 'label' };
    }
    let i = 0;
    while (i < state.specialities.length) {
      let speciality_id = state.specialities[i];
      if (typeof state.customTexts[speciality_id] === 'undefined')
        state.customTexts[speciality_id] = '';
      i++;
    }
    return state.customTexts;
  },
  getCoworkers: state => state.coworkers,
  getLogo: state => state.logo,
};

const mutations = {
  setName(state, value) {
    state.name = value;
  },
  setCoworkerNumber(state, value) {
    state.coworkerNumber = value;
  },

  setLawyers(state, value) {
    state.lawyers = value;
  },

  setEmail(state, email) {
    state.email = email;
  },
  setAddress(state, addressField) {
    state.address = {
      ...state.address,
      ...addressField,
    };
  },
  setSpecialities(state, specialities) {
    state.specialities = specialities;
  },
  setInterventions(state, interventions) {
    state.interventions = interventions;
  },

  setCustomTexts(state, customTexts) {
    state.customTexts = customTexts;
  },

  setCustomText(state, data) {
    console.log(state.customTexts);
    console.log(data.id);
    console.log(data.content);
    state.customTexts[data.id] = data.content;
  },

  setLogo(state, logo) {
    state.logo = logo;
  },
  setUri(state, uri) {
    state.uri = uri;
  },
  setCoworkers(state, coworkers) {
    state.coworkers = coworkers;
  },

  addCoworker(state) {
    state.coworkers.push({
      firstname: '',
      lastname: '',
      socialNetworks: [],
      barLaw: '',
      email: '',
      phoneNumber: '',
      speciality: '',
      profilePicture: '',
      description: '',
    });
  },

  removeCoworker(state, index) {
    state.coworkers.splice(index, 1);
  },

  modifyCoworker(state, coworker) {
    const { index, ...fields } = coworker;

    Object.assign(state.coworkers[index], fields);
  },

  addCoworkerSocialNetwork(state, socialNetwork) {
    const { index, ...social } = socialNetwork;

    state.coworkers[index].socialNetworks.push(social);
  },

  removeCoworkerSocialNetwork(state, socialNetwork) {
    const { index } = socialNetwork;

    state.coworkers[index].socialNetworks = state.coworkers[
      index
    ].socialNetworks.filter(social => social.type !== socialNetwork.type);
  },

  modifyCoworkerSocialNetwork(state, socialNetwork) {
    const { index, ...social } = socialNetwork;

    state.coworkers[index].socialNetworks = [
      ...state.coworkers[index].socialNetworks.filter(s => s.type !== social.type),
      social,
    ];
  },
};

const actions = {
  updateName({ commit }, value) {
    commit('setName', value);
  },
  updateCoworkerNumber({ commit }, value) {
    commit('setCoworkerNumber', value);
  },
  updateLawyers({ commit }, value) {
    commit('setLawyers', value);
  },
  updateEmail({ commit }, email) {
    commit('setEmail', email);
  },
  updateAddress({ commit }, addressField) {
    commit('setAddress', addressField);
  },
  updateSpecialities({ commit }, specialities) {
    commit('setSpecialities', specialities);
  },
  updateInterventions({ commit }, interventions) {
    commit('setInterventions', interventions);
  },
  updateCustomTexts({ commit }, customTexts) {
    commit('setCustomTexts', customTexts);
  },
  updateCustomText({ commit }, data) {
    commit('setCustomText', data);
  },
  updateCoworkers({ commit, dispatch }, coworkers) {
    commit(
      'setCoworkers',
      coworkers.map(coworker => {
        coworker.socialNetworks = coworker.socialNetworks.map(social => {
          const { type, url } = social;

          return {
            type,
            url,
          };
        });

        return coworker;
      })
    );

    dispatch('coworkers/fetchAll', null, { root: true });
  },

  addCoworker({ commit }) {
    commit('addCoworker');
  },

  removeCoworker({ commit }, coworker) {
    commit('removeCoworker', coworker);
  },

  updateCoworker({ commit }, coworker) {
    commit('modifyCoworker', coworker);
  },

  updateCoworkerSocialNetworks({ commit, state }, social) {
    if (!social.url) {
      commit('removeCoworkerSocialNetwork', social);
    }

    if (
      state.coworkers[social.index].socialNetworks.some(s => s.type === social.type)
    ) {
      commit('modifyCoworkerSocialNetwork', social);
      return;
    }

    commit('addCoworkerSocialNetwork', social);
  },

  updateLogo({ commit }, logo) {
    commit('setLogo', logo);
  },
  updateOffice({ commit, dispatch }, office) {
    commit('setLogo', office.logo);
    commit('setSpecialities', office.specialities);
    commit('setInterventions', office.interventions);
    commit('setCustomTexts', office.customTexts);
    commit('setAddress', office.address);
    commit('setEmail', office.email);
    commit('setName', office.name);
    commit('setUri', office['@id']);
    dispatch('updateCoworkers', office.coworkers);
  },

  putCoworkers({ state }) {
    api.put(state.uri, state.coworkers);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
