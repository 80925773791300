<template>
  <Modal class="image-modal" width="medium">
    <template #body>
      <div class="domaines-settings__block">
        <h2 class="title--2">
          Vos domaines d’intervention
        </h2>
        <div class="domains__list">
          <div
            v-for="(intervention, key) in interventions"
            :key="key"
            class="domains__list__item"
          >
            <input
              type="text"
              class="input input--medium"
              placeholder="Domaine d'intervention"
              :value="intervention"
              :class="{ '--empty': intervention === '' }"
              @change="checkIntervention($event, key)"
            />
            <img src="@/assets/svg/Check.svg" class="speciality__checkmark" alt="" />
            <button
              class="button button--delete button--xx-small"
              @click="suppression(key)"
            >
              Supprimer
            </button>
          </div>
          <button
            class="button button--invalid button--x-small"
            @click="addIntervention()"
          >
            Ajouter un domaine
          </button>
        </div>
        <div v-if="interventions.length > 0" class="domains__descriptions">
          <h2 class="title--3">
            <strong>Décrivez en quelques mots</strong><br />
            vos domaines d'intervention
          </h2>
          <div class="descriptions">
            <div
              v-for="(intervention, key) in interventions"
              :key="key"
              class="speciality__description"
            >
              <label :for="`specialities__descripton_${key}`">
                {{ intervention }}
              </label>
              <textarea
                :id="`specialities__descripton_${key}`"
                class="input input--textarea description__input"
                rows="20"
                cols="20"
                :value="customTexts[intervention]"
                placeholder="Informations supplémentaires"
                @change="checkCustomText($event, intervention)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="domaines-settings__block">
        <h2 class="title--2">
          Quelles sont vos spécialités ?
        </h2>
        <div class="domaines-settings__specialities">
          <div
            v-for="speciality in options"
            :key="speciality.id"
            class="speciality"
            :class="{ 'is--disabled': specialities.length >= 3 }"
          >
            <input
              :id="`speciality_${speciality.id}`"
              v-model="specialities"
              type="checkbox"
              class="speciality__checkbox"
              :value="speciality['@id']"
              @change="checkSpeciality"
            />
            <label :for="`speciality_${speciality.id}`">
              <span>{{ speciality.name }}</span>
              <img
                src="@/assets/svg/Check.svg"
                class="speciality__checkmark"
                alt=""
              />
            </label>
          </div>
        </div>

        <div v-if="specialities.length > 0" class="specialities__descriptions">
          <h2 class="title--3">
            <strong>Décrivez en quelques mots</strong><br />
            vos spécialités
          </h2>
          <div class="descriptions">
            <div
              v-for="(speciality_id, key) in specialities"
              :key="key"
              class="speciality__description"
            >
              <label :for="`specialities__descripton_${key}`">
                {{ getSpecialtyName(speciality_id) }}
              </label>
              <textarea
                :id="`specialities__descripton_${key}`"
                class="input input--textarea description__input"
                rows="20"
                cols="20"
                :value="customTexts[speciality_id]"
                placeholder="Informations supplémentaires"
                @change="checkCustomText($event, speciality_id)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="image-modal__cta-container">
        <button
          class="button button--negative button--x-small"
          @click="$emit('cancel')"
        >
          Annuler
        </button>
        <button class="button button--x-small" @click="confirmTheForm">
          Valider
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { Modal } from '@/components';
//import { api } from '@/lib/api';
import { mapActions, mapGetters } from 'vuex';
import HtmlDisplayer from '../classes/HtmlDisplayer';
export default {
  components: { Modal },

  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      pos: 0,
      specialities: [],
      interventions: [],
      customTexts: [],
      photos: [],
      imageUrl: null,
      selectedImageUrl: null,
      image: {
        size: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      getOfficeSpecialities: 'office/getSpecialities',
      getOfficeInterventions: 'office/getInterventions',
      getOfficeCustomTexts: 'office/getCustomTexts',
      getSiteVersionTemplate: 'siteVersion/getTemplate',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },
  },

  async created() {
    this.specialities = this.getOfficeSpecialities;
    this.interventions = this.getOfficeInterventions;
    this.customTexts = this.getOfficeCustomTexts;
  },

  methods: {
    ...mapActions({
      updateOfficeSpecialities: 'office/updateSpecialities',
      updateOfficeInterventions: 'office/updateInterventions',
      updateOfficeCustomTexts: 'office/updateCustomTexts',
    }),

    getSpecialtyName(id) {
      let i = 0;
      while (i < this.options.length) {
        if (this.options[i]['@id'] == id) {
          return this.options[i].name;
        }
        i++;
      }
      return '';
    },

    checkSpeciality(e) {
      let max_specialities = 3;
      if (this.specialities.length >= max_specialities) {
        e.preventDefault();
        e.currentTarget.checked = false;
        this.specialities = this.specialities.slice(0, max_specialities);
        return false;
      }
    },

    suppression(id) {
      this.interventions.splice(id, 1);
      this.updateInterventions();
    },

    addIntervention() {
      this.interventions.push('');
      this.updateInterventions();
    },

    checkCustomText(e, speciality_id) {
      this.customTexts[speciality_id] = e.currentTarget.value;
      this.updateOfficeCustomTexts(this.customTexts);
    },
    checkIntervention(e, key) {
      e.currentTarget.value
        ? e.currentTarget.classList.remove('--empty')
        : e.currentTarget.classList.add('--empty');
      this.interventions[key] = e.currentTarget.value;
      this.interventions = [...this.interventions];
      this.updateInterventions();
    },

    updateInterventions() {
      this.updateOfficeInterventions(
        this.interventions.filter(intervention => intervention !== '')
      );
    },

    confirmTheForm() {
      let htmlDisplayer = new HtmlDisplayer();
      htmlDisplayer.init();

      this.updateOfficeInterventions(this.interventions);
      let specialityList = htmlDisplayer.updateSpecialityBloc({
        interventions: this.interventions,
        customTexts: this.customTexts,
        specialities: this.specialities,
        options: this.options,
        getSiteVersionTemplate: this.getSiteVersionTemplate,
      });
      this.$emit('confirm', this.specialities, specialityList);
    },
  },
};
</script>
