import { api } from '@/lib/api';

const initialState = () => {
  return {
    all: [],
    current: null,
  };
};

const getters = {
  all: state => state.all,

  getCurrent: state => state.current,

  currentScheduleId: state => state.current?.schedule?.['@id'],

  appointmentsModuleEnabled: state => state.current?.appointmentsModuleEnabled,
};

const mutations = {
  setAll(state, coworkers) {
    state.all = coworkers;
  },

  setCurrent(state, coworker) {
    state.current = coworker;
  },
};

const actions = {
  async fetchAll({ commit }) {
    const {
      data: { 'hydra:member': coworkers },
    } = await api.get('/coworkers');

    commit('setAll', coworkers);

    commit('setCurrent', coworkers?.[0]);
  },

  async updateCurrentVideoConferenceCredentials(
    { commit, state },
    { videoConferenceLogin, videoConferencePassword }
  ) {
    const { data } = await api.put(state.current['@id'], {
      videoConferenceLogin,
      videoConferencePassword,
    });

    commit('setCurrent', data);
  },

  async putCurrent({ state }) {
    await api.put(state.current['@id'], state.current);
  },

  async updateCurrent({ commit }, id) {
    const { data } = await api.get(id);

    commit('setCurrent', data);
  },

  updateCurrentSchedule({ state, dispatch }, schedule) {
    if (state.current?.['@id'] !== schedule?.['@id']) {
      return;
    }

    state.current.schedule = schedule;
    dispatch('putCurrent');
  },

  async updateCurrentAppointmentsModuleEnabled(
    { commit, state, dispatch },
    appointmentsModuleEnabled
  ) {
    const { data } = await api.put(state.current['@id'], {
      appointmentsModuleEnabled,
    });

    commit('setCurrent', data);

    dispatch('toastr/notify:save', null, {
      root: true,
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
