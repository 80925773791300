<template>
  <div class="settings-block">
    <p class="text text--instruction">
      POUVEZ-VOUS DÉCRIRE VOTRE CABINET ?
    </p>

    <p class="text text--hint">
      Cette description sera utilisée sur votre site, soyez concis et clair.
      <br />
      La description doit avoir <strong>un minimum de 50 caractères</strong>.
    </p>
    <div class="description">
      <textarea
        class="input input--textarea description__input"
        :maxlength="descriptionMaxLength"
        :placeholder="
          'Vous pouvez indiquer ici une courte description, n\'hésitez pas à mentionner :\n- Structure d\'exercice\n- Réseau dont le cabinet est membre'
        "
        :value="siteVersionDescription"
        @input="updateSiteVersionDescription($event.target.value)"
      ></textarea>
    </div>

    <div class="fees">
      <p class="text text--hint">Précisez vos honoraires (facultatif)</p>
      <textarea
        class="input input--textarea fees__input"
        :maxlength="feesMaxLength"
        :placeholder="
          'Le CNB préconise de mentionner les informations suivantes : \n- Le montant des honoraires. \n- Le mode de détermination des honoraires \n- Les modes de facturation des honoraires. \n- possibilité de saisir le bâtonnier pour contester les honoraires (articles 174 et suivants du décret du 27 novembre 1991) \n- La possibilité de recourir à un médiateur de la consommation'
        "
        :value="siteVersionFees"
        @input="updateSiteVersionFees($event.target.value)"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      descriptionMaxLength: 800,
      feesMaxLength: 800,
    };
  },

  computed: {
    ...mapGetters({
      siteVersionDescription: 'siteVersion/getDescription',
      siteVersionFees: 'siteVersion/getFees',
    }),
  },

  methods: {
    ...mapActions({
      updateSiteVersionDescription: 'siteVersion/updateDescription',
      updateSiteVersionFees: 'siteVersion/updateFees',
    }),
  },
};
</script>
