<template>
  <div class="global-settings__block settings-block-visibility">
    <p class="text text--instruction">
      VISIBILITÉ DE VOTRE SITE INTERNET
    </p>

    <div class="settings-block-visibility__input">
      <img src="@/assets/svg/icon-invisible.svg" class="icon icon--medium" alt="" />

      <label>
        <p-check
          :checked="isSiteVersionInvisible"
          @change="updateSiteVersionInvisible"
        />

        <span class="text">
          En cochant cette case, vous décidez de
          <strong>mettre hors ligne votre site internet</strong>.<br /><br />
          Un message apparaîtra alors pour vos internautes :
          <br />
          <strong>
            Le site est momentanément indisponible.
          </strong>
          <br />
          <br />
          Vous pouvez décocher cette case à tout moment et choisir de remettre votre
          site en mode visible.
          <br />
          <br />
          <strong>
            Vous pouvez procéder à des modifications sur votre site dans la partie
            Prévisualisation uniquement si votre site est en ligne.
          </strong>
          <br />
          Si vous choisissez le mode hors ligne, vous ne pourrez pas modifier votre
          site. Veuillez à bien publier votre site pour activer le mode hors ligne.
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import PCheck from 'pretty-checkbox-vue/check';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { 'p-check': PCheck },

  computed: {
    ...mapGetters({
      isSiteVersionInvisible: 'siteVersion/isOnLine',
    }),
  },

  methods: {
    ...mapActions({
      updateSiteVersionInvisible: 'siteVersion/updateOnLine',
    }),
  },
};
</script>
