<template>
  <transition-group name="toastr" tag="div" class="toastr">
    <p
      v-for="toastr in toasts"
      :key="toastr.time"
      class="toast text text--white"
      @click="removeToast(toastr.time)"
    >
      {{ toastr.message }}
    </p>
  </transition-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      toasts: 'toastr/all',
    }),
  },

  methods: {
    ...mapActions({
      removeToast: 'toastr/remove',
    }),
  },
};
</script>

<style lang="scss">
.toastr-move,
.toastr-enter-active,
.toastr-leave-active {
  transition: all 0.5s ease;
}

.toastr-enter-from,
.toastr-leave-to {
  opacity: 0;
}

.toastr-leave-active {
  position: absolute;
}
</style>
