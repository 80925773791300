<template>
  <div class="global-settings">
    <Specialities />
  </div>
</template>

<script>
import Specialities from '../SettingsBlocks/Specialities';

export default {
  components: {
    Specialities,
  },
};
</script>
