<template>
  <div class="step">
    <p class="text text--hint-large">CRÉATION DE VOTRE SITE INTERNET</p>
    <h1>
      Votre création<br />de site internet commence ici ! <br />
      Vous êtes prêt ?
    </h1>
    <button class="button" @click="$emit('next-step')">C'EST PARTI</button>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$emit('validate', false);
  },
};
</script>
