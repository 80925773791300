<template>
  <div class="global-settings">
    <h1 class="title--1 title--unspaced">
      Paramètres de votre site
    </h1>

    <div class="global-settings__block">
      <div class="settings-block">
        <WebsiteURL />
      </div>
    </div>

    <div class="global-settings__block">
      <div class="settings-block">
        <OnLine />
      </div>
    </div>
  </div>
</template>

<script>
import { OnLine } from '../SettingsBlocks';
import WebsiteURL from '@/views/Dashboard/SiteSettings/SettingsBlocks/WebsiteURL';
export default {
  components: {
    WebsiteURL,
    OnLine,
  },
};
</script>
