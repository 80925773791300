<template>
  <div class="step step--large step--spaced domain-name">
    <h1 class="title--1 title--unspaced">
    </h1>

    <div class="domain-name__body">
      <p class="text text--instruction">
        AVEZ-VOUS DEJA UN SITE EN LIGNE ?
      </p>

      <GroupRadio v-model="siteWebOwner">
        <GroupRadioInput
            :value="true"
            :checked="siteWebOwner === true"
            modifier="radio-input__label--text"
        >
          <span class="radio-input-text radio-input-text--small">
            Oui
          </span>
        </GroupRadioInput>
        <GroupRadioInput
            :value="false"
            :checked="siteWebOwner === false"
            modifier="radio-input__label--text"
        >
          <span class="radio-input-text radio-input-text--small">
            Non
          </span>
        </GroupRadioInput>
      </GroupRadio>
      <p class="text text--hint">
        Un nom de domaine est essentiel dans la création de votre site.
      </p>
    </div>

    <div v-if="siteWebOwner" class="domain-name__form">
      <p class="text text--instruction">RENSEIGNEZ VOTRE NOM DE DOMAINE</p>
      <div class="form-row">
        <input
            v-model="webSiteName"
            class="input"
            type="url"
            placeholder="pearsonspecterlitt.com"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { GroupRadio, GroupRadioInput } from '@/components/common/form';
import { required } from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from 'vuex';
import { domainNameValidator } from '@/mixins/domainNameMixin';

export default {
  components: { GroupRadioInput, GroupRadio },

  data() {
    return {
      siteWebOwner: false,
      webSiteName: '',
    };
  },

  computed: {
    ...mapGetters({
      getWebSiteName: 'site/getWebSiteName',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  methods: {
    ...mapActions({
      updateWebSiteName: 'site/updateWebSiteName',
    }),
  },

  created() {
    this.webSiteName = this.getWebSiteName;
    if (this.webSiteName != '') {
      this.siteWebOwner = true;
    }
    const idAuth = this.getId;
    api.put('/users/' + idAuth, {LastStep: "StepAlreadyWebsite"});
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    webSiteName(value) {
      if (!this.$v.webSiteName.$invalid) {
        this.updateWebSiteName(value);
      }
    },
    siteWebOwner(value) {
      if (value == false){
        this.updateWebSiteName('');
        this.webSiteName = '';
      }
    }
  },

  validations() {
    if (!this.siteWebOwner) {
      return {
      };
    } else {
      return {
        webSiteName: {
          required,
          domainNameValidator,
        },
      };
    }
  },
};
</script>
