<template>
  <div class="appointments-settings">
    <h1 class="appointments__title">
      Paramètres - {{ coworker.firstname }} {{ coworker.lastname }}
    </h1>

    <section class="appointments-settings__section">
      <p-check
        class="p-icon p-smooth appointments-settings__toggle"
        :checked="isAppointmentsModuleEnabled"
        @change="updateModuleEnabled"
      >
        <i slot="extra" class="icon mdi mdi-check"></i>
        Je souhaite proposer la prise de rendez-vous en ligne sur mon site
      </p-check>
      <p>Cette fonctionnalité est gratuite</p>
    </section>

    <section class="appointments-settings__section">
      <h2>Durée des créneaux</h2>
      <TimeSlotDuration :schedule="schedule" />
    </section>

    <section class="appointments-settings__section">
      <h2>Mes disponibilités</h2>
      <div class="appointments-settings__availabilities">
        <Availabitities />
      </div>
    </section>

    <section class="appointments-settings__section">
      <h2>Gérer mes indisponibilités</h2>
      <Unavailabilities :schedule="schedule" />
    </section>

    <section class="appointments-settings__section">
      <h2>Souhaitez-vous recevoir</h2>
      <AppointmentTypes :schedule="schedule" />
    </section>

    <section
      v-if="schedule.videoCallsAppointments"
      class="appointments-settings__section"
    >
      <h2 class="title--2">Votre compte de visioconférence SOLENCY</h2>
      <p class="text text--hint-dark text--with-margin-bottom">
        SOLENCY utilise une solution de visioconférence totalement sécurisée.
      </p>
      <VideoConferenceSettings />
    </section>
  </div>
</template>

<script>
import Availabitities from '@/components/dashboard/appointments/appointment-settings/Availabilities';
import TimeSlotDuration from '@/components/dashboard/appointments/appointment-settings/TimeSlotDuration';
import AppointmentTypes from '@/components/dashboard/appointments/appointment-settings/AppointmentTypes';
import Unavailabilities from '@/components/dashboard/appointments/appointment-settings/Unavailabilities';
import VideoConferenceSettings from '@/components/dashboard/appointments/appointment-settings/VideoConferenceSettings';
import PrettyCheck from 'pretty-checkbox-vue/check';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    VideoConferenceSettings,
    Unavailabilities,
    AppointmentTypes,
    TimeSlotDuration,
    Availabitities,
    'p-check': PrettyCheck,
  },

  computed: {
    ...mapGetters({
      coworker: 'coworkers/getCurrent',
      schedule: 'schedule/state',
      isAppointmentsModuleEnabled: 'coworkers/appointmentsModuleEnabled',
    }),
  },

  methods: {
    ...mapActions({
      updateModuleEnabled: 'coworkers/updateCurrentAppointmentsModuleEnabled',
    }),
  },
};
</script>
