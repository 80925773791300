<template>
  <div class="appointments">
    <div class="dashboard__sub-header appointments__header">
      <div
        class="dashboard__sub-header-tabs-wrapper appointments__header-tabs-wrapper"
      >
        <a
          :class="[
            'dashboard__sub-header-tab appointments__header-tab',
            {
              'dashboard__sub-header-tab--active': isCurrentTab(
                'appointments-settings'
              ),
            },
          ]"
          @click="changeTab('appointments-settings')"
          >Paramètres</a
        >
        <a
          :class="[
            'dashboard__sub-header-tab',
            'appointments__header-tab',
            { 'dashboard__sub-header-tab--active': isCurrentTab('my-appointments') },
          ]"
          @click="changeTab('my-appointments')"
          >Mes rendez-vous</a
        >
      </div>

      <div class="appointments__coworker-selector-wrapper">
        <v-select
          :value="currentCoworker"
          class="appointments__coworker-selector"
          label="fullName"
          :options="coworkers"
          :clearable="false"
          @input="onInput"
        >
          <span slot="no-options">Aucun collaborateur trouvé.</span>
        </v-select>
      </div>
    </div>

    <div class="appointments-body">
      <MyAppointments
        v-if="isCurrentCoworkerDefined"
        v-show="isCurrentTab('my-appointments')"
        :current-tab="currentTab"
      />
      <AppointmentsSettings v-show="isCurrentTab('appointments-settings')" />
    </div>
  </div>
</template>

<script>
import AppointmentsSettings from '@/views/Dashboard/Appointments/AppointmentsSettings';
import MyAppointments from '@/views/Dashboard/Appointments/MyAppointments';
import VueSelect from 'vue-select';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { MyAppointments, AppointmentsSettings, 'v-select': VueSelect },

  data() {
    return {
      currentTab: 'my-appointments',
    };
  },

  computed: {
    ...mapGetters({
      coworkers: 'coworkers/all',
      getSchedule: 'schedule/state',
      getCurrentCoworker: 'coworkers/getCurrent',
    }),

    currentCoworker: {
      get() {
        return this.getCurrentCoworker;
      },
      async set(value) {
        await this.updateCurrentCoworker(value);
      },
    },

    schedule: {
      get() {
        return this.getSchedule;
      },
      async set(value) {
        await this.updateSchedule(value);
      },
    },

    isCurrentCoworkerDefined() {
      return !!this.currentCoworker;
    },
  },

  async created() {
    await this.refreshCoworkers();
  },

  methods: {
    ...mapActions({
      fetchCoworkers: 'coworkers/fetchAll',
      fetchSchedule: 'schedule/fetch',
      updateCurrentCoworker: 'coworkers/updateCurrent',
      updateSchedule: 'schedule/updateState',
    }),

    changeTab(tab) {
      this.currentTab = tab;
    },

    isCurrentTab(tab) {
      return this.currentTab === tab;
    },

    async refreshCoworkers() {
      await this.fetchCoworkers();
      await this.fetchSchedule(this.currentCoworker.schedule['@id']);
    },

    async onInput($event) {
      await this.updateCurrentCoworker($event['@id']);
      await this.fetchSchedule(this.currentCoworker.schedule['@id']);
    },
  },
};
</script>
