<template>
  <div class="listing">
    <ul>
      <li class="speciality_card">
        <div class="inner">
          <h5 id="Speciality1-title">Droits de femmes</h5>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
//import { Modal } from '@/components';
//import { api } from '@/lib/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {},
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({}),
  },

  methods: {
    ...mapActions({}),
  },
};
</script>
