<template>
  <div class="video-conference-settings">
    <div
      v-if="isAccountCreated"
      class="video-conference-settings__account-information"
    >
      <p class="video-conference-settings__cta">Informations de connexion</p>

      <CredentialsComponent
        :login="login"
        :password="password"
        @edit-credentatials="showEditCredentialsModal"
      />
    </div>

    <div v-if="!isAccountCreated" class="video-conference-settings__create-account">
      <p class="video-conference-settings__cta">
        Vous ne semblez pas encore posséder de compte admin pour la visioconférence.
      </p>

      <button
        class="video-conference-settings__button-create-account"
        @click="showEditCredentialsModal"
      >
        Créer mon compte
      </button>
    </div>

    <Modal
      v-if="mustCredentialsModalBeShown"
      class="video-conference-settings__edit-credentials-modal"
    >
      <template #header>
        <h2 v-if="isAccountCreated" class="title title--2">
          Modification des identifiants
        </h2>
        <h2 v-else class="title title--2">Créer un compte</h2>
      </template>

      <template #body>
        <CircularLoader v-if="loading" />

        <p class="text text--2">Nom d'utilisateur :</p>

        <input
          v-model="newLogin"
          class="input"
          placeholder="Choisissez un nom d'utilisateur"
        />

        <p class="text text--2">Mot de passe :</p>

        <div
          v-if="!isAccountCreated"
          class="video-conference-settings__default-password-checkbox-wrapper"
          @click="toggleUseDefaultPassword"
        >
          <p-check
            v-model="useDefaultPassword"
            class="video-conference-settings__default-password-checkbox"
          >
            <i slot="extra" class="icon mdi mdi-check"></i>
            Utiliser le mot de passe par défaut
          </p-check>
        </div>

        <input
          v-model="newPassword"
          class="input"
          :type="passwordEditFieldType"
          :disabled="mustPasswordFieldBeDisabled"
          placeholder="Choisissez un mot de passe"
        />

        <p
          v-if="mustCredentialsInvalidErrorMessageBeShown"
          class="text text--not-available text--center"
        >
          Veuillez choisir un nom d'utilisateur et un mot de passe d'au moins 6
          caractères.
        </p>
      </template>

      <template #footer>
        <div class="video-conference-settings__edit-credentials-validation-buttons">
          <button class="button button--x-small" @click="hideEditCredentialsModal">
            Annuler
          </button>

          <button
            :class="[
              'button',
              'button--x-small',
              { 'button--invalid': areCredentialsInvalid },
            ]"
            :disabled="areCredentialsInvalid"
            @click="editAccountCredentials"
          >
            <span v-if="isAccountCreated">Enregistrer</span>
            <span v-else>Créer mon compte</span>
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import CredentialsComponent from '@/components/dashboard/appointments/appointment-settings/CredentialsComponent';
import Modal from '@/components/Modal';
import PrettyCheck from 'pretty-checkbox-vue/check';
import { minLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import CircularLoader from '@/components/common/CircularLoader';

const CREDENTIAL_FIELDS_VALIDATORS = {
  minLength: minLength(6),
  required,
};

export default {
  components: {
    CircularLoader,
    Modal,
    CredentialsComponent,
    'p-check': PrettyCheck,
  },

  data() {
    return {
      createAccountEndpoint: '/CreateModerator.php',
      newLogin: this.login,
      newPassword: this.password,
      showErrorMessage: false,
      mustCredentialsModalBeShown: false,
      useDefaultPassword: !this.isAccountCreated,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      coworker: 'coworkers/getCurrent',
    }),

    token() {
      return this.coworker.id;
    },

    login() {
      return this.coworker?.['videoConferenceLogin'];
    },

    password() {
      return this.coworker?.['videoConferencePassword'];
    },

    isAccountCreated() {
      return this.coworker?.['videoConferenceAccountCreated'] ?? false;
    },

    passwordEditFieldType() {
      return !this.isAccountCreated && this.useDefaultPassword ? 'text' : 'password';
    },

    areCredentialsInvalid() {
      return this.$v.$invalid;
    },

    mustCredentialsInvalidErrorMessageBeShown() {
      return (
        this.areCredentialsInvalid &&
        this.newLogin !== undefined &&
        this.newPassword !== undefined &&
        this.newLogin !== '' &&
        this.newPassword !== ''
      );
    },

    mustPasswordFieldBeDisabled() {
      return this.useDefaultPassword && !this.isAccountCreated;
    },
  },

  methods: {
    ...mapActions({
      updateCredentials: 'coworkers/updateCurrentVideoConferenceCredentials',
    }),

    showEditCredentialsModal() {
      this.newLogin = this.login;
      this.newPassword = this.password;
      this.mustCredentialsModalBeShown = true;
    },

    hideEditCredentialsModal() {
      this.mustCredentialsModalBeShown = false;
    },

    toggleUseDefaultPassword() {
      this.newPassword = this.password;
      this.useDefaultPassword = !this.useDefaultPassword;
    },

    async editAccountCredentials() {
      try {
        this.loading = true;

        await this.updateCredentials({
          videoConferenceLogin: this.newLogin,
          videoConferencePassword: this.newPassword,
        });
      } catch (error) {
        this.showErrorMessage = true;
      }

      this.loading = false;
      this.hideEditCredentialsModal();
    },
  },

  watch: {
    password(oldPassword, newPassword) {
      if (oldPassword !== newPassword) {
        this.newPassword = this.password;
      }
    },

    login(oldLogin, newLogin) {
      if (oldLogin !== newLogin) {
        this.newLogin = this.login;
      }
    },
  },

  validations() {
    return {
      newPassword: {
        ...CREDENTIAL_FIELDS_VALIDATORS,
      },
      newLogin: {
        ...CREDENTIAL_FIELDS_VALIDATORS,
      },
    };
  },
};
</script>
