<template>
  <Auth>
    <h2 class="title title--2">
      Pour commencer, créez votre <br />compte gratuitement
    </h2>
    <p class="text text--hint">
      Cela vous permettra d'accéder à votre plateforme personnelle !
    </p>

    <form @submit.prevent="register" class="register-form">
      <div class="form-row">
        <input v-model="name" type="text" class="input" placeholder="Votre nom" />
        <input
          v-model="firstname"
          type="text"
          class="input"
          placeholder="Votre prénom"
        />
      </div>
      <div class="form-row">
        <input
          v-model="email"
          type="email"
          class="input"
          placeholder="Votre email"
        />
      </div>
      <div class="form-row">
        <input
          v-model="emailConfirmation"
          type="email"
          class="input"
          placeholder="Confirmation de votre email"
        />
      </div>
      <div class="form-row">
        <input
          v-model="password"
          type="password"
          class="input"
          placeholder="Votre mot de passe"
        />
        <input
          v-model="passwordConfirmation"
          type="password"
          class="input"
          placeholder="Confirmation"
        />
      </div>

      <div class="register-form__acceptances-checkboxes-list">
        <div class="register-form__acceptance">
          <p-check
            v-model="termsOfUsageAccepted"
            class="p-default p-curve p-thick register-form__acceptance-checkbox"
            name="touAccepted"
            color="primary-o"
          />
          <span class="register-form__acceptance-label checkbox-label--required">
            J'accepte sans restriction ni réserve, les
            <a
              class="link"
              href="https://api.solency.com/files/Conditions_d_utilisation-Solency.pdf"
              target="_blank"
              >Conditions Générales d’Utilisation</a
            >
          </span>
        </div>

        <div class="register-form__acceptance register-form__acceptance--cnil">
          <p-check
            v-model="cnilNoticesAccepted"
            class="p-default p-curve p-thick register-form__acceptance-checkbox"
            name="cnilNoticesAccepted"
            color="primary-o"
          />

          <span class="register-form__acceptance-label checkbox-label--required">
            J'ai pris connaissance et j'accepte les mentions CNIL suivantes :
          </span>

          <div class="register-form__acceptance-details">
            <span class="text--on-background">
              SOLENCY est une marque de KERIALIS.
            </span>

            <span class="text--on-background">
              KERIALIS en sa qualité de responsable de traitement, met en œuvre des
              traitements de données à caractère personnel ayant pour finalité
              l’accompagnement de cabinets d’avocats dans leur transition numérique.
            </span>

            <span class="text--on-background">
              Vous disposez d'un droit d'accès, d'opposition, d'interrogation, de
              rectification, d'effacement, de portabilité, de limitation du
              traitement des données à caractère personnel vous concernant.
            </span>

            <span class="text--on-background">
              Vous pouvez exercer ces droits par l'envoi d'un courrier simple à
              KERIALIS Prévoyance , RGPD - 80 rue Saint-Lazare - 75009 Paris ou par
              e-mail à :
              <a
                class="link--no-decoration"
                href="mailto:rgpd@kerialis.fr"
                target="_blank"
                >rgpd@kerialis.fr</a
              >.
            </span>

            <span class="text--on-background">
              Vous disposez par ailleurs, du droit d’introduire une réclamation
              auprès de la Commission Nationale de l’Informatique et des Libertés
              (CNIL), notamment sur son site internet
              <a class="link--no-decoration" href="https://cnil.fr" target="_blank"
                >www.cnil.fr</a
              >.
            </span>

            <span class="text--on-background">
              Plus d’informations sur :
              <a
                class="link--no-decoration"
                href="https://www.solency.com/dist/imgs/Notice_RGPD-Solency.pdf"
                target="_blank"
                >notice RGPD</a
              >.
            </span>
          </div>
        </div>

        <div class="register-form__acceptance">
          <p-check
            v-model="commercialSolicitationAccepted"
            class="p-default p-curve p-thick register-form__acceptance-checkbox"
            name="commercialSolicitationAccepted"
            color="primary-o"
          />
          <span class="register-form__acceptance-label">
            J'accepte de recevoir des communications de SOLENCY et de ses
            partenaires.
          </span>
        </div>
      </div>

      <div v-if="$v.email.required && $v.email.$invalid">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Vérifiez l'authenticité de votre adresse mail
        </p>
      </div>
      <div v-if="$v.emailConfirmation.required && $v.emailConfirmation.$invalid">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Les deux adresses mails sont différentes
        </p>
      </div>
      <div v-if="$v.password.required && $v.password.$invalid">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Votre mot de passe doit contenir un minimum de huit caractères ainsi qu'une
          majuscule et un caractère spécial
        </p>
      </div>
      <div
        v-if="$v.passwordConfirmation.required && $v.passwordConfirmation.$invalid"
      >
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Les deux mots de passe doivent être identiques
        </p>
      </div>
      <div v-if="emailAvaibility === false">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Cette adresse mail est déjà utilisée
        </p>
      </div>
      <div v-if="unauthorizedCharactersError">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Votre nom ou prénom contient des caractères spéciaux
        </p>
      </div>
      <div v-if="error === true">
        <p class="text text--not-available">
          <img src="@/assets/svg/Bad-Check.svg" alt="" />
          <br />
          Une erreur est survenue, contactez un administrateur
        </p>
      </div>
      <button
        :class="['button', { 'button--invalid': $v.$invalid || isLoading }]"
        type="submit"
      >
        <span v-if="!isLoading">S'INSCRIRE</span>
        <Loader v-else />
      </button>
    </form>
  </Auth>
</template>

<script>
import { Auth } from '@/components';
import { sameAs, email, required, minLength } from 'vuelidate/lib/validators';
import { api } from '@/lib/api';
import { mapActions } from 'vuex';
import Loader from '@/components/common/Loader';
import PCheck from 'pretty-checkbox-vue/check';

export default {
  components: { Loader, Auth, 'p-check': PCheck },
  
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: 'Créez un compte Solency pour créer votre site web dédié à votre cabinet d’avocats et découvrez notre marketplace qui vous aidera à digitaliser votre cabinet.' }
      ]
    }
  },

  data() {
    return {
      name: '',
      firstname: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      emailAvaibility: true,
      unauthorizedCharactersError: false,
      error: false,
      isLoading: false,
      termsOfUsageAccepted: false,
      cnilNoticesAccepted: false,
      commercialSolicitationAccepted: false,
    };
  },

  watch: {
    email() {
      this.emailAvaibility = null;
    },

    emailConfirmation() {
      this.emailAvaibility = null;
    },
  },

  methods: {
    ...mapActions({
      updateAuth: 'auth/updateAuth',
    }),

    async register() {
      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;

      try {
        await api.post('/users', {
          email: this.email,
          firstname: this.firstname,
          lastname: this.name,
          plainPassword: this.password,
          termsOfUsageAccepted: this.termsOfUsageAccepted,
          cnilNoticesAccepted: this.cnilNoticesAccepted,
          commercialSolicitationAccepted: this.commercialSolicitationAccepted,
        });
      } catch (error) {
        this.isLoading = false;

        let violations = error.response.data.violations.map(
          violation => violation.propertyPath
        );

        this.unauthorizedCharactersError =
          violations.includes('firstname') || violations.includes('lastname');

        this.emailAvaibility = !violations.includes('email');

        return;
      }

      try {
        const {
          data: { token },
        } = await api.post('/auth_tokens', {
          email: this.email,
          password: this.password,
        });

        await this.updateAuth(token);

        await api.post('/email_confirmation_tokens', {});

        this.$router.push({ name: 'VerifyEmail' });
      } catch {
        this.error = true;
        this.isLoading = false;
      }

      this.isLoading = false;
    },
  },

  validations: {
    name: {
      required,
    },
    firstname: {
      required,
    },
    email: {
      email,
      required,
    },
    emailConfirmation: {
      sameAs: sameAs('email'),
      required,
    },
    password: {
      required,
      minLength: minLength(8),
      goodPassword: password => {
        return /\W+/.test(password) && /[A-Z]+/.test(password);
      },
    },
    passwordConfirmation: {
      sameAs: sameAs('password'),
      required,
    },
    termsOfUsageAccepted: {
      checked: value => value === true,
    },
    cnilNoticesAccepted: {
      checked: value => value === true,
    },
  },
};
</script>
