<template>
  <div class="step">
    <h1>
      Pour commencer, quel est vôtre <br />
      profession
    </h1>
    <div class="form-row">
      <input
        v-model="profession"
        class="input input--font-large"
        type="text"
        placeholder="vôtre profession Avocat / Notaire ..."
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import {api} from "@/lib/api";

export default {
  data() {
    return {
      profession: '',
    };
  },

  created() {
    this.profession = this.getProfession;

    const idAuth = this.getId;
    api.put('/users/' + idAuth, {LastStep: "StepProffesion"});
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    profession(value) {
      if (!this.$v.profession.$invalid) {
        this.updateProfession(value);
      }
    },
  },

  computed: {
    ...mapGetters({
      getProfession: 'user/getProfession',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  methods: {
    ...mapActions({
      updateProfession: 'user/updateProfession',
    }),
  },

  validations: {
    profession: {
      required,
    },
  },
};
</script>
