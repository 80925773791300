<template>
  <div
    class="appointments-unavailabilities"
    :class="{
      'appointments-unavailabilities--disabled': !isAppointmentsModuleEnabled,
    }"
  >
    <date-picker
      v-model="currentDay.date"
      :attributes="vCalendarAttributes"
      color="green"
      is-expanded
      :disabled="!isAppointmentsModuleEnabled"
      @update:from-page="calendarPageChanged"
    />

    <section>
      <p class="legend legend--green">: Je suis disponible toute la journée</p>
      <p class="legend legend--yellow">
        : Je suis disponible une partie de la journée
      </p>
      <p class="legend legend--red">: Je suis indisponible toute la journée</p>
    </section>

    <Modal
      v-if="showUnavailabilityModal"
      class="appointments-unavailability__modal"
      :padding-top="false"
      width="medium"
      close-button
      @closeModalRequest="closeModal"
    >
      <template v-slot:header>
        <h2 class="title title--2 title--bold title--unspaced text--center">
          Modifier votre indisponibilité pour la journée du
          {{ formattedDateFR(currentDay.date) }}
        </h2>
      </template>

      <template v-slot:body>
        <div class="appointments-unavailability__modal-body">
          <div class="checkbox-wrapper cursor--pointer" @click="toggleAllDay">
            <div class="checkbox-wrapper__input">
              <p-check
                v-model="currentDay.allDayUnavailabilityEnabled"
                class="p-default p-curve p-thick"
                color="primary-o"
              />
            </div>
            <span class="text text--2 text--hint-light">
              Me rendre indisponible toute la journée
            </span>
          </div>

          <p class="text--1">OU</p>

          <div
            class="checkbox-wrapper cursor--pointer"
            @click="toggleCustomAvailability"
          >
            <div class="checkbox-wrapper__input">
              <p-check
                v-model="currentDay.customAvailabilityEnabled"
                class="p-default p-curve p-thick"
                color="primary-o"
              />
            </div>
            <span class="text text--2 text--hint-light">
              Sélectionnez votre disponibilité
            </span>
          </div>

          <div class="appointments-unavailability__custom-availabilities">
            <TimeSelector
              v-model="currentDay.morning"
              :min="8"
              :max="12"
              :disabled="!areCustomAvailabilitySelectorsEnabled"
            />

            <TimeSelector
              v-model="currentDay.afternoon"
              :min="14"
              :max="18"
              :disabled="!areCustomAvailabilitySelectorsEnabled"
            />
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="buttons-group buttons-group--2">
          <button
            class="button button--x-small button--negative"
            @click="closeModal"
          >
            <span>ANNULER</span>
          </button>
          <button class="button button--x-small" @click="postCurrentDay">
            <img
              v-if="loading"
              src="@/assets/images/loader.gif"
              alt=""
              class="appointments-unavailabilities__loader"
            />
            <span v-else>VALIDER</span>
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { Modal } from '@/components';
import PrettyCheck from 'pretty-checkbox-vue/check';
import { api } from '@/lib/api';
import { datesMixin } from '@/mixins/datesMixin';
import TimeSelector from '@/components/dashboard/appointments/appointment-settings/TimeSelector';
import { DEFAULT_DAILY_AVAILABILITIES } from '@/consts/availabilities';
import { isEqual } from 'lodash';
import { mapGetters } from 'vuex';

const DEFAULT_CURRENT_DAY = {
  date: null,
  allDayUnavailabilityEnabled: false,
  customAvailabilityEnabled: false,
  allDayUnavailability: null,
  customAvailability: null,
  morning: [8, 12],
  afternoon: [14, 18],
};

export default {
  components: {
    TimeSelector,
    'date-picker': DatePicker,
    Modal,
    'p-check': PrettyCheck,
  },
  mixins: [datesMixin],

  props: {
    schedule: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      customAvailabilities: [],
      unavailabilities: [],
      currentMonth: {
        month: null,
        year: null,
      },
      currentDay: {
        ...DEFAULT_CURRENT_DAY,
      },
      vCalendarAttributes: [],
      showUnavailabilityModal: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      isAppointmentsModuleEnabled: 'coworkers/appointmentsModuleEnabled',
    }),

    areCustomAvailabilitySelectorsEnabled() {
      return (
        !this.currentDay.allDayUnavailabilityEnabled &&
        this.currentDay.customAvailabilityEnabled
      );
    },
  },

  watch: {
    'currentDay.date': function(oldDate, newDate) {
      if (this.currentDay.date !== null && oldDate !== newDate) {
        this.showModal();
      }
    },
    'schedule.coworker': async function(oldCoworker, newCoworker) {
      if (oldCoworker !== newCoworker) {
        await this.refreshUnavailabilities();
      }
    },
    schedule: async function() {
      await this.refreshUnavailabilities();
    },
  },

  methods: {
    showModal() {
      this.refreshCurrentDay();
      this.showUnavailabilityModal = true;
    },

    closeModal() {
      this.showUnavailabilityModal = false;
      this.currentDay = { ...DEFAULT_CURRENT_DAY };
    },

    toggleAllDay() {
      this.currentDay.allDayUnavailabilityEnabled = !this.currentDay
        .allDayUnavailabilityEnabled;
      this.currentDay.customAvailabilityEnabled = false;
    },

    toggleCustomAvailability() {
      this.currentDay.customAvailabilityEnabled = !this.currentDay
        .customAvailabilityEnabled;
      this.currentDay.allDayUnavailabilityEnabled = false;
    },

    async getUnavailabilities(
      formattedFirstDate,
      formattedLastDate,
      strictlyBefore = false
    ) {
      return (
        await api.get(
          this.schedule.id +
            '/unavailabilities?date[after]=' +
            formattedFirstDate +
            '&date' +
            (strictlyBefore ? '[strictly_before]' : '[before]') +
            '=' +
            formattedLastDate
        )
      ).data['hydra:member'];
    },

    async getCustomAvailabilities(
      formattedFirstDate,
      formattedLastDate,
      strictlyBefore = false
    ) {
      return (
        await api.get(
          this.schedule.id +
            '/availabilities?date[after]=' +
            formattedFirstDate +
            '&date' +
            (strictlyBefore ? '[strictly_before]' : '[before]') +
            '=' +
            formattedLastDate
        )
      ).data['hydra:member'];
    },

    async refreshUnavailabilities() {
      if (!this?.schedule.id) {
        return;
      }

      const firstDate = new Date(
          this.currentMonth.year,
          this.currentMonth.month - 1,
          1
        ),
        formattedFirstDate = this.formattedDateUS(firstDate),
        formattedLastDate = this.formattedDateUS(this.nextMonth(firstDate).toDate());

      this.unavailabilities = await this.getUnavailabilities(
        formattedFirstDate,
        formattedLastDate,
        true
      );

      this.customAvailabilities = await this.getCustomAvailabilities(
        formattedFirstDate,
        formattedLastDate,
        true
      );

      this.refreshVCalendarAttributes();
    },

    calendarPageChanged(event) {
      this.currentMonth = {
        month: event.month,
        year: event.year,
      };
      this.refreshUnavailabilities();
    },

    refreshVCalendarAttributes() {
      this.vCalendarAttributes = [
        ...this.unavailabilities.map(unavailability => {
          return {
            key: unavailability['@id'],
            dot: 'red',
            dates: unavailability.date,
          };
        }),
        ...this.customAvailabilities.map(availability => {
          return {
            key: availability['@id'],
            dot: 'yellow',
            dates: availability.date,
          };
        }),
        ...this.schedule.jsonAvailabilities.map((availability, index) => ({
          dates: {
            start: new Date(),
            weekdays: [((index + 1) % 7) + 1],
          },
          dot: this.getDotColor(availability),
          excludeDates: [
            ...this.unavailabilities.map(unavailability => unavailability.date),
            ...this.customAvailabilities.map(availability => availability.date),
          ],
        })),
      ];
    },

    async refreshCurrentDayUnavailability(formattedDate) {
      const currentDayUnavailabilities = await this.getUnavailabilities(
        formattedDate,
        formattedDate
      );

      if (currentDayUnavailabilities.length === 0) {
        this.currentDay.allDayUnavailabilityEnabled = false;
        this.currentDay.allDayUnavailability = null;

        return;
      }

      this.currentDay.allDayUnavailabilityEnabled = true;
      this.currentDay.allDayUnavailability = currentDayUnavailabilities[0];
    },

    async refreshCurrentDayCustomAvailability(formattedDate) {
      const currentDayCustomAvailabilities = await this.getCustomAvailabilities(
        formattedDate,
        formattedDate
      );

      if (currentDayCustomAvailabilities.length === 0) {
        this.currentDay.customAvailabilityEnabled = false;
        this.currentDay.customAvailability = null;

        return;
      }

      this.currentDay.customAvailabilityEnabled = true;
      this.currentDay.customAvailability = currentDayCustomAvailabilities[0];
    },

    async refreshCurrentDay() {
      const currentDay = this.formattedDateUS(this.currentDay.date);

      await this.refreshCurrentDayUnavailability(currentDay);

      if (this.currentDay.allDayUnavailabilityEnabled) {
        this.currentDay.customAvailabilityEnabled = false;
        this.currentDay.customAvailability = [];
      } else {
        await this.refreshCurrentDayCustomAvailability(currentDay);
      }

      if (this.currentDay.customAvailabilityEnabled) {
        this.currentDay.morning = [
          this.hourToInt(
            new Date(this.currentDay.customAvailability.morningStart).getHours()
          ),
          this.hourToInt(
            new Date(this.currentDay.customAvailability.morningEnd).getHours()
          ),
        ];
        this.currentDay.afternoon = [
          this.hourToInt(
            new Date(this.currentDay.customAvailability.afternoonStart).getHours()
          ),
          this.hourToInt(
            new Date(this.currentDay.customAvailability.afternoonEnd).getHours()
          ),
        ];
      }
    },

    async postCurrentDay() {
      this.loading = true;

      let refreshVUnavailabilities = false;

      if (this.currentDay.allDayUnavailabilityEnabled) {
        await api.post('unavailabilities', {
          date: this.currentDay.date,
          schedule: this.schedule.id,
        });
        refreshVUnavailabilities = true;
      } else if (this.currentDay.customAvailabilityEnabled) {
        await api.post('availabilities', {
          date: this.currentDay.date,
          schedule: this.schedule.id,
          morningStart: this.intToHour(this.currentDay.morning[0]),
          morningEnd: this.intToHour(this.currentDay.morning[1]),
          afternoonStart: this.intToHour(this.currentDay.afternoon[0]),
          afternoonEnd: this.intToHour(this.currentDay.afternoon[1]),
        });
        refreshVUnavailabilities = true;
      } else if (
        this.currentDay.allDayUnavailability !== null &&
        this.currentDay.allDayUnavailability['@id'] !== undefined
      ) {
        await api.delete(this.currentDay.allDayUnavailability['@id']);
        refreshVUnavailabilities = true;
      } else if (
        this.currentDay.customAvailability !== null &&
        this.currentDay.customAvailability['@id'] !== undefined
      ) {
        await api.delete(this.currentDay.customAvailability['@id']);
        refreshVUnavailabilities = true;
      }

      if (refreshVUnavailabilities) {
        await this.refreshUnavailabilities();
      }

      this.loading = false;

      this.closeModal();
    },

    getDotColor(availability) {
      if (availability[0] === false) {
        return 'red';
      }

      if (isEqual(availability, DEFAULT_DAILY_AVAILABILITIES)) {
        return 'green';
      }

      return 'yellow';
    },
  },
};
</script>
