<template>
  <div class="live-edit">
    <div v-if="netlifyUrl != ''" class="share">
      Pour prévisualiser et partager votre site,
      <a :href="netlifyUrl" target="_blank">cliquez ici</a> !
    </div>

    <iframe
      v-if="!isInternetExplorer"
      ref="iframe"
      class="live-edit__iframe"
      scrolling="no"
      frameborder="0"
      :style="{ width: previewSize }"
    >
    </iframe>
    <div v-else class="text text--2 text--center">
      <p class="text--large">
        Votre navigateur n'est pas supporté par notre plateforme car il n'est plus à
        jour.
      </p>
      <p>
        Pour utiliser cette fonctionnalité, merci d'utiliser un navigateur moderne
        tel que Chrome, Firefox, Safari ou Edge.
      </p>
    </div>

    <ImageModal
      v-if="showImageUploadModal"
      @cancel="closeModal"
      @confirm="handleConfirm"
    />
    <SpecialitySelect
      v-if="showSpecialitySelect"
      :options="specialityOptions"
      @cancel="endSpecialitySelect"
      @confirm="handleSpecialitySelect"
    />
    <Modal v-if="showBravoModal" class="live-edit__modal" :icon="true">
      <template v-slot:body>
        <p class="title--2 title--bold title--unspaced">BRAVO !</p>
        <p class="title--3 title--unspaced">Voici votre site.</p>
        <p class="text text--2 text--hint-light text--with-margin-bottom">
          Vous pouvez encore le peaufiner avant de le mettre en ligne.
        </p>
        <button class="button button--x-small" @click="showBravoModal = false">
          <span>Continuer</span>
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { ImageModal, SpecialitySelect } from '@/components';
import { DEV_DOMAINS } from '@/consts/availabilities';
import Modal from '../../../../components/Modal';
import { api } from '@/lib/api';
import HtmlDisplayer from '../../../../classes/HtmlDisplayer';

export default {
  components: { SpecialitySelect, Modal, ImageModal },

  props: {
    netlifyUrl: {
      type: String,
      default: '',
    },
    previewSize: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      black: '#333',
      blue: '#31b39f',
      green: '#78D978',
      iframe: null,
      currentlyEditedElementId: null,
      currentlyEditedImage: null,
      showImageUploadModal: false,
      showSpecialitySelect: false,
      specialityOptions: [],
      showBravoModal: true,
    };
  },

  computed: {
    ...mapGetters({
      getSiteVersionTemplate: 'siteVersion/getTemplate',
      getOfficeSpecialities: 'office/getSpecialities',
      getOfficeInterventions: 'office/getInterventions',
      getOfficeCustomTexts: 'office/getCustomTexts',
      getSiteVersionSite: 'siteVersion/getSite',
      getSiteVersionContent: 'siteVersion/getContent',
      getSiteVersionStatus: 'siteVersion/getStatus',
      getSiteHostingSettings: 'site/getHostingSettings',
      siteVersionDescription: 'siteVersion/getDescription',
      siteVersionFees: 'siteVersion/getFees',
      getOfficeAddress: 'office/getAddress',
      getOfficeEmail: 'office/getEmail',
      getOfficeName: 'office/getName',
      getOfficeState: 'office/getState',
    }),
    isInternetExplorer() {
      const userAgent = window.navigator.userAgent;
      const msie = userAgent.indexOf('MSIE ');
      return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
    },
  },

  watch: {
    previewSize() {
      this.iframe.style.height =
        this.iframe.contentWindow.document.documentElement.scrollHeight + 'px';
    },
    async getSiteHostingSettings() {
      await this.refreshIframe();
    },
  },

  async mounted() {
    this.iframe = this.$refs.iframe;
    const { data } = await api.get('/specialities');
    this.specialityOptions = data['hydra:member'];
    //await api.post('/offices', this.getOfficeState);
    await this.refreshIframe();
    this.updateShowFirstCreationModal(false);
  },

  methods: {
    ...mapActions({
      updateOfficeSpecialities: 'office/updateSpecialities',
      updateSiteVersionContentElementById: 'siteVersion/updateContentElementById',
      updateOfficeName: 'office/updateName',
      updateSiteVersionDescription: 'siteVersion/updateDescription',
      updateSiteVersionFees: 'siteVersion/updateFees',
      updateOfficeAddress: 'office/updateAddress',
      updateOfficeEmail: 'office/updateEmail',
      updateOfficeCustomText: 'office/updateCustomText',
      updateShowFirstCreationModal: 'settings/updateShowFirstCreationModal',
    }),

    setSiteVersionDescription(data) {
      this.updateSiteVersionDescription(data.content);
    },

    setSiteVersionHoraires(data) {
      this.updateSiteVersionFees(data.content);
    },

    setOfficeNameValue(data) {
      this.updateOfficeName(data.content);
    },

    setAddressCityValue(data) {
      this.updateOfficeAddress({ city: data.content });
    },

    setAddressPostalCodeValue(data) {
      this.updateOfficeAddress({ postalCode: data.content });
    },

    setAddressAddressLine1Value(data) {
      this.updateOfficeAddress({ addressLine1: data.content });
    },

    setAddressPhoneNumberValue(data) {
      this.updateOfficeAddress({ phoneNumber: data.content });
    },

    setOfficeEmailValue(data) {
      this.updateOfficeEmail(data.content);
    },

    makeLinkEditable(query, callback, getValue) {
      const tag = this.iframe.contentWindow.document.querySelector(query);
      if (getValue != undefined && tag != undefined) {
        tag.innerHTML = getValue;
      }
      if (tag != undefined) {
        this.makeElementEditable(tag, 'link');
        /*
        tag.setAttribute('href', '/#');
        tag.addEventListener('click', e => {
          e.preventDefault();
        });*/
        this.makeTextEditable(tag, callback);
      }
    },

    processContentForLiveEdit() {
      this.formatIframeForDisplay(this.iframe);

      /**/
      let domaines_interventions_content = this.iframe.contentWindow.document.querySelector(
        '#domaines_interventions'
      );
      if (domaines_interventions_content) {
        domaines_interventions_content.style.position = 'relative';
        this.makeSelectEditable(
          domaines_interventions_content,
          'domaines_interventions'
        );
      }

      /*
      let list = domaines_interventions_content.querySelector('.listing');
      console.log(list.innerHTML);
      console.log(specialityList);

      list.innerHTML = specialityList;
      */
      /*
      domaines_interventions_content.addEventListener('click', () => {
        this.currentlyEditedElementId = id;
        this.currentlyEditedImage = domElement.querySelector('img');
      });
       */

      /**/

      const links = this.iframe.contentWindow.document.querySelectorAll('a');
      links.forEach(link => {
        if (!link.href.match('#.*')) {
          link.target = '_blank';
        }
      });

      this.iframe.contentWindow.document.querySelector(
        '#contact button'
      ).disabled = true;

      const matomoElements = this.iframe.contentWindow.document.querySelectorAll(
        '.matomo-script, #optout-form'
      );

      if (matomoElements) {
        Object.keys(matomoElements).forEach(el => {
          matomoElements[el].parentNode.removeChild(matomoElements[el]);
        });
      }

      let showMoreSections = this.iframe.contentWindow.document.querySelectorAll(
        '.more-desc'
      );
      if (showMoreSections) {
        showMoreSections.forEach(section => {
          const viewMore = section.querySelector('.view-more');
          if (viewMore !== null && viewMore !== undefined) {
            viewMore.addEventListener('click', () => {
              section.classList.toggle('is--expanded');
            });
          }
        });
      }

      /* Address start */
      const ContactAddress = this.iframe.contentWindow.document.querySelector(
        '#Contact-address'
      );
      if (ContactAddress != undefined) {
        ContactAddress.setAttribute('href', '/#');
        ContactAddress.addEventListener('click', e => {
          e.preventDefault();
        });
        ContactAddress.outerHTML =
          '<div id="Contact-address" className="link_under is_orange" target="_blank" rel="noreferrer">' +
          '<div id="officeAddressAddressLine1">' +
          this.getOfficeAddress.addressLine1 +
          '</div>' +
          '<div id="officeAddressCity">' +
          this.getOfficeAddress.city +
          '</div>' +
          '<div id="officeAddressPostalCode">' +
          this.getOfficeAddress.postalCode +
          '</div>' +
          '</div>';
      }
      /* Address end */
      this.makeLinkEditable(
        'h1 > .link_anchor_nav',
        this.setOfficeNameValue,
        this.getOfficeName
      );
      this.makeLinkEditable(
        '#officeAddressAddressLine1',
        this.setAddressAddressLine1Value
      );
      this.makeLinkEditable('#officeAddressCity', this.setAddressCityValue);
      this.makeLinkEditable(
        '#officeAddressPostalCode',
        this.setAddressPostalCodeValue
      );
      this.makeLinkEditable(
        '#Contact-phone',
        this.setAddressPhoneNumberValue,
        this.getOfficeAddress.phoneNumber
      );
      this.makeLinkEditable(
        '#Contact-mail',
        this.setOfficeEmailValue,
        this.getOfficeEmail
      );
      /* Description start */
      const moreDesc = this.iframe.contentWindow.document.querySelector(
        '.more-desc'
      );
      if (moreDesc != undefined) {
        this.makeElementEditable(moreDesc, 'text');
        this.makeTextEditable(
          moreDesc,
          this.setSiteVersionDescription,
          this.siteVersionDescription
        );
      }
      /* Description end */
      /* Description start */
      const moreHoraires = this.iframe.contentWindow.document.querySelector(
        '.more-horaires'
      );
      if (moreHoraires != undefined) {
        this.makeElementEditable(moreHoraires, 'text');
        this.makeTextEditable(
          moreHoraires,
          this.setSiteVersionHoraires,
          this.siteVersionFees
        );
      }
      /* Description end */
      this.getSiteVersionContent.forEach(element => {
        const domElement = this.iframe.contentWindow.document.getElementById(
          element.id
        );

        if (!domElement) {
          return;
        }

        this.makeElementEditable(domElement, element.type);

        switch (element.type) {
          case 'text':
            this.makeTextEditable(
              domElement,
              this.updateSiteVersionContentElementById
            );
            break;
          case 'image':
            this.makeImageEditable(domElement, element.id);
            break;
          default:
            break;
        }
      });
      let htmlDisplayer = new HtmlDisplayer();

      let specialityList = htmlDisplayer.updateSpecialityBloc({
        interventions: this.getOfficeInterventions,
        customTexts: this.getOfficeCustomTexts,
        specialities: this.getOfficeSpecialities,
        options: this.specialityOptions,
        getSiteVersionTemplate: this.getSiteVersionTemplate,
      });
      this.handleSpecialitySelect(this.getOfficeSpecialities, specialityList);
    },

    formatIframeForDisplay(iframe) {
      iframe.style.height =
        iframe.contentWindow.document.documentElement.scrollHeight + 'px';

      iframe.contentWindow.document.getElementById('App').style.minHeight = '0px';

      const link = document.createElement('link');
      link.href = 'https://fonts.googleapis.com/css2?family=Montserrat&display=swap';
      link.rel = 'stylesheet';

      iframe.contentWindow.document.head.appendChild(link);
    },

    makeTextEditable(domElement, callBack, getValue) {
      if (getValue != undefined) {
        domElement.innerHTML = getValue;
      }
      domElement.contentEditable = true;

      callBack.bind(this);
      domElement.addEventListener('input', event => {
        callBack({
          id: domElement.id,
          tag: domElement,
          type: 'text',
          content: event.target.textContent,
        });
      });
    },

    makeImageEditable(domElement, id) {
      if (domElement.tagName !== 'DIV') {
        domElement = domElement.parentNode;
      }

      const uploadSvg = require('@/assets/svg/upload.svg');
      const uploadLabel = document.createElement('span');
      const uploadIcon = document.createElement('img');
      uploadIcon.src = uploadSvg;

      uploadLabel.appendChild(uploadIcon);
      uploadLabel.addEventListener('click', () => {
        this.currentlyEditedElementId = id;
        this.currentlyEditedImage = domElement.querySelector('img');

        this.openModal();
      });

      Object.assign(uploadLabel.style, {
        position: 'absolute',
        top: '0',
        left: '0',
        margin: '10px 0 0 10px',
        padding: '5px',
        background: this.green,
        borderRadius: '5px',
        cursor: 'pointer',
      });

      domElement.appendChild(uploadLabel);
    },

    makeSelectEditable(domElement, id) {
      if (domElement.tagName !== 'DIV') {
        domElement = domElement.parentNode;
      }
      Object.assign(domElement.style, {
        // border: `2px dashed ${this.blue}`,
        margin: '10px',
      });
      const editLabel = document.createElement('span');
      editLabel.textContent = 'ÉDITER LES DOMAINES ET SPECIALITES';
      editLabel.contentEditable = false;
      editLabel.addEventListener('click', () => {
        this.currentlyEditedElementId = id;
        this.currentlyEditedImage = domElement.querySelector('img');

        this.startSpecialitySelect();
      });
      Object.assign(editLabel.style, {
        position: 'absolute',
        cursor: 'pointer',
        top: '0px',
        left: '0px',
        transform: 'translate(0px, -100%)',
        padding: '14px 20px',
        margin: '6px',
        backgroundColor: this.blue,
        color: 'white',
        borderRadius: '3px',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: '11px',
        lineHeight: '1.5',
      });
      domElement.appendChild(editLabel);
      let card_contents = domElement.querySelectorAll('.custom-text');
      let pos = 0;
      let that = this;
      card_contents.forEach(card_content => {
        //card_content.dataset.pos = pos;
        this.makeElementEditable(card_content, 'text');
        this.makeTextEditable(card_content, function(data) {
          /*
          console.log({
            id: data.tag.dataset.id,
            content: data.content,
          });*/
          that.updateOfficeCustomText({
            id: data.tag.dataset.id,
            content: data.content.trim(),
          });
        });
        pos = pos + 1;
      });
    },

    handleConfirm(url) {
      this.currentlyEditedImage.src = url;
      this.updateSiteVersionContentElementById({
        id: this.currentlyEditedElementId,
        content: url,
        type: 'image',
      });
      this.endSpecialitySelect();
    },

    makeElementEditable(domElement, type) {
      let wrapper = domElement;
      wrapper = document.createElement('div');
      if (type == 'link') {
        Object.assign(wrapper.style, {
          border: `1px dashed ${this.green}`,
          position: 'relative',
          'min-width': '100px',
          'min-height': '40px',
          'margin-bottom': '30px',
          //margin: window.getComputedStyle(domElement).margin,
        });
      } else {
        Object.assign(wrapper.style, {
          border: `1px dashed ${this.green}`,
          position: 'relative',
          'min-width': '100px',
          'min-height': '40px',
          //'margin-bottom': '30px',
          margin: window.getComputedStyle(domElement).margin,
        });
      }

      if (type !== 'image') {
        Object.assign(wrapper.style, {
          width: domElement.offsetWidth + 'px',
        });
      }

      Object.assign(domElement.style, {
        margin: '0',
      });

      Object.assign(domElement.style, {
        outline: 'none',
      });

      const editLabel = document.createElement('span');
      editLabel.textContent = 'ÉDITABLE';
      editLabel.contentEditable = false;

      Object.assign(editLabel.style, {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        transform: 'translate(-1px, -100%)',
        height: '21px',
        padding: '4px 12px',
        margin: '0',
        backgroundColor: this.green,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: '11px',
        lineHeight: '1.5',
        textAlign: 'left',
      });

      domElement.parentNode.replaceChild(wrapper, domElement);
      wrapper.appendChild(domElement);

      wrapper.appendChild(editLabel);
    },

    async refreshIframe() {
      const siteVersionStatus = this.getSiteVersionStatus.toLowerCase();

      /*
      if (DEV_DOMAINS.includes(window.location.hostname))
        this.netlifyUrl = this.getSiteHostingSettings[siteVersionStatus].url;
       */

      if (1 || DEV_DOMAINS.includes(window.location.hostname)) {
        if (typeof this.getSiteVersionSite.externalId == 'string') {
          this.netlifyUrl =
            window.location.protocol +
            '//' +
            window.location.hostname +
            '/preview?externalId=' +
            this.getSiteVersionSite.externalId;
        }
      }

      let { data: iframeContent } = await axios.get(
        this.getSiteHostingSettings[siteVersionStatus].url
      );

      iframeContent = iframeContent.replace(
        './',
        this.getSiteHostingSettings[siteVersionStatus].url + '/'
      );

      const iframeDocument = this.iframe.contentDocument;
      iframeDocument.open('text/html', 'replace');
      iframeDocument.write(iframeContent);
      iframeDocument.close();

      this.iframe.addEventListener('load', this.processContentForLiveEdit);
    },

    startSpecialitySelect() {
      this.showSpecialitySelect = true;
    },

    handleSpecialitySelect(specialities, specialityList) {
      this.showSpecialitySelect = false;
      this.updateOfficeSpecialities(specialities);

      let domaines_interventions_content = this.iframe.contentWindow.document.querySelector(
        '#domaines_interventions'
      );
      let list = domaines_interventions_content.querySelector('.listing');

      if (list == null)
        list = domaines_interventions_content.querySelector(
          '.domaines_interventions_content'
        );
      if (list == null)
        list = domaines_interventions_content.querySelector(
          '.domaines_interventions_slider>.swiper-container>.swiper-wrapper'
        );
      if (list == null)
        list = domaines_interventions_content.querySelector(
          '.specialities__swiper>.swiper-wrapper'
        );
      list.innerHTML = specialityList;
      this.makeSelectEditable(
        domaines_interventions_content,
        'domaines_interventions'
      );
    },

    endSpecialitySelect() {
      this.showSpecialitySelect = false;
    },

    openModal() {
      this.showImageUploadModal = true;
    },

    closeModal() {
      this.showImageUploadModal = false;
    },
  },
};
</script>
