<template xmlns="http://www.w3.org/1999/html">
  <div class="step">
    <h1>
      Très bien. Maintenant, parlons <br />
      un peu de votre équipe.
    </h1>
    <div class="form-row">
      <textarea
          v-model="laywer"
          class="input input--font-large"
      rows="15"
          style="height: auto;"
      ></textarea>
      <!--
      <input
        v-model="coworkerNumber"
        class="input input--font-large"
        type="text"
        placeholder="Nom du cabinet"
      />
      -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import {api} from "@/lib/api";

export default {
  data() {
    return {
      laywer: '',
    };
  },

  created() {
    this.laywer = this.getLaywer;

    const idAuth = this.getId;
    api.put('/users/' + idAuth, {LastStep: "StepLawyers"});
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    laywer(value) {
      if (!this.$v.laywer.$invalid) {
        this.updateLaywer(value);
      }
    },
  },

  computed: {
    ...mapGetters({
      getLaywer: 'office/getLawyers',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  methods: {
    ...mapActions({
      updateLaywer: 'office/updateLawyers',
    }),
  },

  validations: {
    laywer: {
      required,
    },
  },
};
</script>
