<template>
  <div class="step step--wide step--big-spaced">
    <h1 class="title title--1">
      Très bien. <br />
      Maintenant, parlons un peu de votre équipe.
    </h1>
    <p class="text text--instruction">QUI SONT LES AVOCATS DE VOTRE CABINET ?</p>
    <p class="text text--hint">
      Nous ajoutons par défaut le prénom et le nom renseigné précédemment.<br />
      <strong>N'hésitez pas à le modifier si besoin</strong>.<br />
      Vous pouvez ajouter un ou plusieurs avocats.
    </p>
    <div class="coworkers">
      <div
        v-for="(coworker, index) in getOfficeCoworkers"
        :key="index"
        class="form-row"
      >
        <input
          type="text"
          class="input"
          placeholder="Nom"
          :value="coworker.lastname"
          @input="updateOfficeCoworker({ index, lastname: $event.target.value })"
        />
        <input
          type="text"
          class="input"
          placeholder="Prénom"
          :value="coworker.firstname"
          @input="updateOfficeCoworker({ index, firstname: $event.target.value })"
        />
        <img
          v-if="index > 0"
          src="@/assets/svg/Remove.svg"
          class="icon-space"
          @click="removeOfficeCoworker(index)"
        />
      </div>
      <p class="link" @click="addOfficeCoworker">
        + Ajouter une personne supplémentaire
      </p>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';
// import ProfilePicture from '../../Dashboard/SiteSettings/SettingsBlocks/ProfilePicture.vue';

export default {
  // components: { ProfilePicture },

  computed: {
    ...mapGetters({
      getOfficeCoworkers: 'office/getCoworkers',
      getAuth: 'auth/getId',
      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  async created() {
    api.put('/users/' + this.getAuth, { LastStep: 'StepCoWorkers' });
    if (this.getOfficeCoworkers.length) {
      return;
    }
    const { data: user } = await api.get(`/users/${this.getAuth}`);
    this.addOfficeCoworker();
    this.updateOfficeCoworker({
      index: 0,
      lastname: user.lastname,
      firstname: user.firstname,
    });
  },

  mounted() {
    this.$emit('validate', false);
  },

  methods: {
    ...mapActions({
      addOfficeCoworker: 'office/addCoworker',
      removeOfficeCoworker: 'office/removeCoworker',
      updateOfficeCoworker: 'office/updateCoworker',
    }),
  },
};
</script>
