<template>
  <div class="card a" :class="`is--${data.slug}`">
    <div class="inner">
      <div class="card-inner">
        <header>
          <span class="card-title" v-html="data.title" />
          <p>{{ data.content }}</p>
        </header>
        <a
          v-if="data.link"
          :href="data.link"
          :target="data.self ? '_self' : '_blank'"
        >
          Cliquez ici
        </a>
        <router-link
          v-if="data.rooterLink"
          class="dashboard__header__tabs__tab"
          :to="{ name: data.rooterLink }"
        >
          Cliquez ici
        </router-link>
      </div>
      <div v-if="data.image" class="card-image">
        <img :src="data.image" :alt="data.title" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
