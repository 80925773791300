<template>
  <div class="step">
    <h1>
      Quel est le nom<br />
      de votre cabinet d'avocat ?
    </h1>
    <div class="form-row">
      <input
        v-model="officeName"
        class="input input--font-large"
        type="text"
        placeholder="Nom du cabinet"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { api } from '@/lib/api';

export default {
  data() {
    return {
      officeName: '',
    };
  },

  computed: {
    ...mapGetters({
      getOfficeName: 'office/getName',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    officeName(value) {
      if (!this.$v.officeName.$invalid) {
        this.updateOfficeName(value);
      }
    },
  },

  created() {
    this.officeName = this.getOfficeName;

    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepOfficeName' });
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  methods: {
    ...mapActions({
      updateOfficeName: 'office/updateName',
    }),
  },

  validations: {
    officeName: {
      required,
    },
  },
};
</script>
