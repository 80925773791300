<template>
  <div class="step">
    <h1>Où est situé votre cabinet ?</h1>
    <div class="form-row">
      <input
        v-model="officeAddress"
        type="text"
        class="input"
        placeholder="N° et rue"
      />
    </div>
    <div class="form-row">
      <input
        v-model="officePostalCode"
        type="text"
        class="input input--small"
        placeholder="Code postal"
      />
      <input
        v-model="officeCity"
        type="text"
        class="input input--large"
        placeholder="Ville"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, numeric, maxLength, minLength } from 'vuelidate/lib/validators';
import { api } from '@/lib/api';

export default {
  data() {
    return {
      officeAddress: '',
      officePostalCode: '',
      officeCity: '',
    };
  },

  computed: {
    ...mapGetters({
      getOfficeAddress: 'office/getAddress',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    officeAddress(value) {
      if (!this.$v.officeAddress.$invalid) {
        this.updateOfficeAddress({ addressLine1: value });
      }
    },

    officePostalCode(value) {
      if (!this.$v.officePostalCode.$invalid) {
        this.updateOfficeAddress({ postalCode: value });
      }
    },

    officeCity(value) {
      if (!this.$v.officeCity.$invalid) {
        this.updateOfficeAddress({ city: value });
      }
    },
  },

  created() {
    this.officeAddress = this.getOfficeAddress.addressLine1;
    this.officePostalCode = this.getOfficeAddress.postalCode;
    this.officeCity = this.getOfficeAddress.city;

    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepAdress' });
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  methods: {
    ...mapActions({
      updateOfficeAddress: 'office/updateAddress',
    }),
  },

  validations: {
    officeAddress: { required },
    officePostalCode: {
      required,
      numeric,
      maxLength: maxLength(5),
      minLength: minLength(5),
    },
    officeCity: { required },
  },
};
</script>
