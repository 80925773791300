const initialState = () => {
  return {
    showFirstCreationModal: false,
  };
};

const getters = {
  getState: state => state,
  getShowFirstCreationModal: state => state.showFirstCreationModal,
};

const mutations = {
  setShowFirstCreationModal(state, domainName) {
    state.showFirstCreationModal = domainName;
  },
};

const actions = {
  updateShowFirstCreationModal({ commit }, showFirstCreationModal) {
    commit('setShowFirstCreationModal', showFirstCreationModal);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
