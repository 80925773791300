<template>
  <div class="global-settings">
    <div class="global-settings__block">
      <div class="settings-block">
        <div class="settings-block">
          <h1 class="title--1 title--unspaced">Modifier vos informations</h1>
          <p class="text text--hint account__intro">
            Mettez à jour votre nom et vos coordonnées personnelles.
          </p>
          <div class="settings-block__settings">
            <div class="row">
              <div class="form-group">
                <label for="firstname">Prénom</label>
                <input
                  id="firstname"
                  class="input"
                  type="text"
                  placeholder="Prénom"
                  :value="getFirstname"
                  @input="setFirstname($event.target.value)"
                />
              </div>
              <div class="form-group">
                <label for="name">nom</label>
                <input
                  id="name"
                  class="input"
                  type="text"
                  placeholder="Nom"
                  :value="getLastname"
                  @input="setLastname($event.target.value)"
                />
              </div>
              <div class="form-group --full">
                <label for="adresse">Adresse</label>
                <input
                  id="adresse"
                  type="text"
                  class="input"
                  placeholder="Adresse"
                  :value="getAddress"
                  @input="setAddress($event.target.value)"
                />
              </div>
              <div class="form-group">
                <label for="zip">Code postal</label>
                <input
                  id="zip"
                  type="text"
                  class="input"
                  placeholder="Code postal"
                  :value="getPostalCode"
                  @input="setPostalCode($event.target.value)"
                />
              </div>
              <div class="form-group">
                <label for="city">Ville</label>
                <input
                  id="city"
                  type="text"
                  class="input"
                  placeholder="Ville"
                  :value="getCity"
                  @input="setCity($event.target.value)"
                />
              </div>
              <div class="form-group --full">
                <label for="phone">Téléphone</label>
                <input
                  id="phone"
                  type="text"
                  class="input"
                  placeholder="Téléphone"
                  :value="getTelephone"
                  @input="setTelephone($event.target.value)"
                />
              </div>
            </div>

            <div class="site-settings__step-buttons-wrapper">
              <button
                class="site-settings__next-step-button button button--icon button--x-small"
                @click="nextStep"
              >
                <span>Sauvegarder</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { api } from '@/lib/api';

export default {
  components: {},

  data() {
    return {
      currentTab: 'my-appointments',
    };
  },
  computed: {
    ...mapGetters({
      getAuth: 'auth/getId',
      getFirstname: 'user/getFirstname',
      getLastname: 'user/getLastname',
      getUserState: 'user/getState',

      getTelephone: 'user/getTelephone',
      getAddress: 'user/getAddress',
      getPostalCode: 'user/getPostalCode',
      getCity: 'user/getCity',
      // getNewEmail: 'user/getNewEmail',
    }),
  },

  async created() {
    const { data: user } = await api.get(`/users/${this.getAuth}`);
    console.log(user.firstname);
    console.log(user.lastname);
    console.log(user.telephone);

    console.log(user.address);
    console.log(user.postalCode);
    console.log(user.city);
    // console.log(user.newEmail);

    this.setEmail(user.email);
    this.setFirstname(user.firstname);
    this.setLastname(user.lastname);
    this.setTelephone(user.telephone);
    this.setAddress(user.address);
    this.setPostalCode(user.postalCode);
    this.setCity(user.city);
    // this.setNewEmail(user.newEmail);
  },

  methods: {
    ...mapActions({
      fetchCoworkers: 'coworkers/fetchAll',
      fetchSchedule: 'schedule/fetch',
      updateCurrentCoworker: 'coworkers/updateCurrent',
      setFirstname: 'user/updateFirstname',
      setLastname: 'user/updateLastname',

      setEmail: 'user/updateEmail',
      setTelephone: 'user/updateTelephone',
      setAddress: 'user/updateAddress',
      setPostalCode: 'user/updatePostalCode',
      setCity: 'user/updateCity',
      // setNewEmail: 'user/updateNewEmail',
    }),

    async nextStep() {
      await api.put('/users/' + this.getAuth, this.getUserState);

      /*
      if (this.getNewEmail.length > 0) {
        await api.post('/new_email_confirmation_tokens', {
          email: this.getNewEmail,
        });
      }*/
    },

    changeTab(tab) {
      this.currentTab = tab;
    },

    isCurrentTab(tab) {
      return this.currentTab === tab;
    },

    async refreshCoworkers() {
      await this.fetchCoworkers();
      await this.fetchSchedule(this.currentCoworker.schedule['@id']);
    },

    async onInput($event) {
      await this.updateCurrentCoworker($event['@id']);
      await this.fetchSchedule(this.currentCoworker.schedule['@id']);
    },
  },
};
</script>
