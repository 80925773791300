<template>
  <div class="step step--large step--spaced">
    <h1 class="title title--1">
      Comment avez-vous<br />
      connu Solency ?
    </h1>
    <div class="hearing">
      <div class="form-row">
        <select v-model="solencyHearing" class="input input--font-large --arrowed">
          <option disabled value="">Choisissez une raison</option>
          <option>Bouche à oreille</option>
          <option>Sur le web (site , réseaux sociaux ...)</option>
          <option>Dans la presse</option>
        </select>
        <div class="input-arrow">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
            />
          </svg>
        </div>
      </div>
      <div v-if="solencyHearing == 'Autre'" class="form-row">
        <input
          id="input-date"
          v-model="other"
          type="text"
          class="input"
          placeholder="Veuillez préciser..."
          value=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { api } from '@/lib/api';

export default {
  data() {
    return {
      solencyHearing: '',
      other: '',
    };
  },
  computed: {
    ...mapGetters({
      getSolencyHearing: 'user/getSolencyHearing',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    solencyHearing(value) {
      if (!this.$v.solencyHearing.$invalid) {
        if (value != 'Autre') this.updateSolencyHearing(value);
      }
    },

    other(value) {
      this.updateSolencyHearing(value);
    },
  },

  created() {
    this.solencyHearing = this.getSolencyHearing;

    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepSolencyHearing' });
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  methods: {
    ...mapActions({
      updateSolencyHearing: 'user/updateSolencyHearing',
    }),
  },

  validations: {
    solencyHearing: {
      required,
    },
  },
};
</script>
