import { apiSetJwt } from '@/lib/api';

const state = {
  userLogged: false,
  eLearningUrl: '',
  profession: '',
  solencyHearing: '',
  firstname: '',
  lastname: '',
  email: '',
  telephone: '',
  address: '',
  postalCode: '',
  city: '',
  newEmail: '',
  alreadyClient: '',
};

const getters = {
  getState: state => state,
  isLogged: state => state.userLogged,
  getELearningUrl: state => state.eLearningUrl,
  getProfession: state => state.profession,
  getSolencyHearing: state => state.solencyHearing,
  getFirstname: state => state.firstname,

  getTelephone: state => state.telephone,
  getAddress: state => state.address,
  getPostalCode: state => state.postalCode,
  getCity: state => state.city,
  getNewEmail: state => state.newEmail,

  getLastname: state => state.lastname,
  getEmail: state => state.email,
  getAlreadyClient: state => state.alreadyClient,
};

const mutations = {
  setUserLogged(state, token) {
    state.userLogged = true;
    apiSetJwt(token);
  },

  logout(state) {
    state.userLogged = false;
    apiSetJwt('');
  },

  setAlreadyClient(state, value) {
    state.alreadyClient = value;
  },
  setELearningUrl(state, url) {
    state.eLearningUrl = url;
  },
  setProfession(state, value) {
    state.profession = value;
  },
  setSolencyHearing(state, value) {
    state.solencyHearing = value;
  },
  setFirstname(state, value) {
    state.firstname = value;
  },
  setTelephone(state, value) {
    state.telephone = value;
  },
  setAddress(state, value) {
    state.address = value;
  },
  setPostalCode(state, value) {
    state.postalCode = value;
  },
  setCity(state, value) {
    state.city = value;
  },
  setNewEmail(state, value) {
    state.newEmail = value;
  },
  setEmail(state, value) {
    state.email = value;
  },

  setLastname(state, value) {
    state.lastname = value;
  },
};

const actions = {
  login({ commit, rootGetters }, token) {
    if (rootGetters['auth/isTokenValid']) {
      commit('setUserLogged', token);
    }
  },

  logout({ commit }) {
    commit('logout');
  },

  updateUser({ commit }, user) {
    commit('setELearningUrl', user.eLearningUrl);
    commit('setProfession', user.email);
    commit('setFirstname', user.firstname);
    commit('setTelephone', user.telephone);
    commit('setAddress', user.address);
    commit('setPostalCode', user.postalCode);
    commit('setCity', user.city);
    commit('setEmail', user.email);
    commit('setLastname', user.lastname);
  },

  updateSolencyHearing({ commit }, value) {
    commit('setSolencyHearing', value);
  },
  updateAlreadyClient({ commit }, value) {
    commit('setAlreadyClient', value);
  },
  updateProfession({ commit }, value) {
    commit('setProfession', value);
  },
  updateFirstname({ commit }, value) {
    commit('setFirstname', value);
  },

  updateTelephone({ commit }, value) {
    commit('setTelephone', value);
  },
  updateAddress({ commit }, value) {
    commit('setAddress', value);
  },
  updatePostalCode({ commit }, value) {
    commit('setPostalCode', value);
  },
  updateCity({ commit }, value) {
    commit('setCity', value);
  },
  updateNewEmail({ commit }, value) {
    commit('setNewEmail', value);
  },
  updateEmail({ commit }, value) {
    commit('setEmail', value);
  },
  updateLastname({ commit }, value) {
    commit('setLastname', value);
  },
};

export default { namespaced: true, actions, state, mutations, getters };
