<template>
  <div class="dashboard-home">
    <div class="dashboard-home__header">
      <h1>Gérer mon site.</h1>
      <p>
        Accédez directement à l’édition de vos contenus, aux différentes
        fonctionnalités de votre site mais aussi au blog Solency.
      </p>
    </div>

    <div class="dashboard-home__cards">
      <HomeBox v-for="(box, index) in getBoxes" :key="index" :data="box" />
    </div>

    <div v-if="getSiteVersionStatus === 'PROD'">
      <div class="dashboard-home__header">
        <h2>Ma messagerie</h2>
        <p>
          Retrouvez l’ensemble des messages qui vous ont été adressés depuis le
          formulaire de contact de votre site.
        </p>
      </div>

      <div v-if="messages.length" class="dashboard-home__messages">
        <Message
          v-for="(message, index) in messages"
          :key="index"
          :message="message"
        ></Message>
      </div>
      <p v-else class="text text--large text--center">Vous n'avez aucun message !</p>
    </div>

    <transition name="modal">
      <Modal
        v-if="getShowFirstCreationModal"
        close-button
        class="Creation"
        width=""
        @closeModalRequest="onCloseModalRequest"
      >
        <template v-slot:header>
          <div>
            <img class="poulpie" src="@/assets/images/poulpie.png" />
            <h3>
              Vous venez de terminer<br />la création de votre site, félicitation !
            </h3>
          </div>
        </template>
        <template v-slot:body>
          <h4>Attention, votre site n'est pas encore en ligne.</h4>
          <p class="text text--2">
            Pour le mettre en ligne, rendez-vous sur la page
            <strong>"Votre site"</strong>,<br />dans l'onglet
            <strong>"Prévisualisation"</strong>.
          </p>

          <router-link
            :to="{ name: 'SiteSettings', hash: 'LiveEdit' }"
            class="site-settings__next-step-button button button--icon button--x-small"
          >
            Je publie maintenant
          </router-link>

          <p>
            Nous vous donnons la possibilité d'ajouter des détails sur vos avocats
            (description, barreau de rattachement, photo de profil ...)<br /><br />
            Vous devez également
            <strong>mettre à jour vos mentions légales à l'étape 6</strong>.<br />
            Un exemple est disponible à titre indicatif.
            <br /><br />
            Bonne continuation sur <strong>Solency</strong> !
          </p>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script>
import HomeBox from '@/components/HomeBox.vue';
import Message from '@/views/Dashboard/Messages/Message';
import { Modal } from '@/components';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Message,
    HomeBox,
    Modal,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showFirstCreationModal: true,
    };
  },
  computed: {
    ...mapGetters({
      getShowFirstCreationModal: 'settings/getShowFirstCreationModal',
      eLearningUrl: 'user/getELearningUrl',
      getSiteVersionStatus: 'siteVersion/getStatus',
    }),
    getToken() {
      const token = this.eLearningUrl.split('https://formations.solency.com')[1];
      return token;
    },
    itemsToDisplay() {
      return this.items.filter(
        item => item.buttonRoute !== 'Stats' || this.getSiteVersionStatus === 'PROD'
      );
    },
    getBoxes() {
      return [
        {
          slug: 'site',
          title: 'Paramètres de mon site',
          content:
            "Accéder directement à l'édition de vos contenus et à la modification graphique de votre site web.",
          image: require('@/assets/images/souris.png'),
          rooterLink: 'SiteSettings',
          link: null,
        },
        {
          slug: 'messagerie',
          title: 'Messagerie',
          content:
            'Consultez les messages reçus sur votre site web et recontactez vos clients.',
          image: require('@/assets/images/messagerie.png'),
          rooterLink: 'Mailbox',
          link: null,
        },
        /*
        {
          slug: 'elearning',
          title: 'Mon espace<br/>e-learning',
          content: `Suivre le modèle de formation complet ou commencer par écouter le podcast : à vous de choisir !`,
          image: require('@/assets/images/casque-micro.png'),
          rooterLink: null,
          link: this.eLearningUrl,
        },
        */
        {
          slug: 'appointments',
          title: 'Rendez-vous',
          content:
            'Activez cette fonctionnalité une fois votre site créé : vos prospects et clients pourront prendre RDV en quelques clics',
          image: require('@/assets/images/block-calendar.png'),
          rooterLink: 'Appointments',
          link: null,
        },
        {
          slug: 'blog',
          title: 'Mon blog',
          content:
            'Écrivez, vous aussi, vos propres actualités et partagez-les à votre audience.',
          image: require('@/assets/images/crayons.png'),
          rooterLink: 'Posts',
        },
        /*
        {
          slug: 'marketplace',
          title: 'Marketplace',
          content:
            'Trouvez des solutions clés en main, créées pour vous, à des prix défiant toute concurrence.',
          image: require('@/assets/images/caddie.png'),
          rooterLink: null,
          link: `https://marketplace.solency.com/${this.getToken}`,
        },
        {
          slug: 'knowledge',
          title: 'Base de connaissances',
          content:
            'Parcourez les différents types de contenus disponibles ou effectuez une recherche.',
          image: require('@/assets/images/main-livre.png'),
          rooterLink: null,
          link: `https://formations.solency.com/base-de-connaissances-solency${this.getToken}`,
        },
        */
        {
          slug: 'dashboard',
          title: 'Mon tableau de bord',
          content: "Suivez l'activité de votre cabinet avec votre tableau de bord.",
          image: require('@/assets/images/block-loupe.png'),
          rooterLink: 'Stats',
          link: null,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      updateShowFirstCreationModal: 'settings/updateShowFirstCreationModal',
    }),

    onCloseModalRequest() {
      this.updateShowFirstCreationModal(false);
    },
  },
};
</script>
