<template>
  <div class="colors-input">
    <GroupRadio @input="$emit('input', $event)">
      <GroupRadioInput
        v-for="(color, index) in colors"
        :key="index"
        :value="color"
        :checked="isEqual(color, selectedColor)"
      >
        <RadioColor :primary="color.primary" :secondary="color.secondary">
          <img
            v-if="isEqual(color, selectedColor)"
            src="@/assets/svg/Check.svg"
            class="check-icon"
            alt=""
          />
        </RadioColor>
      </GroupRadioInput>
    </GroupRadio>

    <p class="text text--instruction">
      OU COMPOSEZ VOTRE PROPRE DUO DE COULEUR
    </p>

    <p class="text text--hint">
      Si vous optez pour un duo de couleur personnalisé,<br />
      N’hésitez pas à tester le rendu visuel depuis la « prévisualisation ». <br />
      Vous pourrez modifier votre choix si le rendu ne vous convient pas.
    </p>

    <GroupRadio @input="$emit('input', $event)">
      <GroupRadioInput
        :id="`colorRadio_${colors.length + 1}`"
        :value="customColorConfig"
        :checked="isEqual(customColorConfig, selectedColor)"
      >
        <RadioColor
          :primary="customColorConfig.primary"
          :secondary="customColorConfig.secondary"
        >
          <img
            v-if="isEqual(customColorConfig, selectedColor)"
            src="@/assets/svg/Check.svg"
            class="check-icon"
            alt=""
          />
        </RadioColor>
      </GroupRadioInput>
    </GroupRadio>

    <div
      v-if="isEqual(customColorConfig, selectedColor)"
      class="radio-input-color__custom-inputs"
    >
      <Chrome
        :value="customColorConfig.primary"
        disable-alpha
        @input="updateCustomColorConfig('primary', $event.hex)"
      />
      <Chrome
        :value="customColorConfig.secondary"
        disable-alpha
        @input="updateCustomColorConfig('secondary', $event.hex)"
      />
    </div>
  </div>
</template>

<script>
import { GroupRadio, GroupRadioInput } from '@/components/common/form';
import { RadioColor } from '@/components';
import { isEqual } from 'lodash';
import Chrome from 'vue-color/src/components/Chrome';

export default {
  components: { GroupRadio, GroupRadioInput, RadioColor, Chrome },

  props: {
    selectedColor: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      customColorConfig: {
        primary: '#FFFFFF',
        secondary: '#FFFFFF',
        reversed: false,
      },
      colors: [
        {
          primary: '#1D1D1D',
          secondary: '#1CA7A8',
          reversed: false,
        },
        {
          primary: '#0A375C',
          secondary: '#91E1DC',
          reversed: false,
        },
        {
          primary: '#252F33',
          secondary: '#5C1446',
          reversed: false,
        },
        {
          primary: '#252F33',
          secondary: '#ED8431',
          reversed: false,
        },
        {
          primary: '#000000',
          secondary: '#FFFFFF',
          reversed: true,
        },
      ],
    };
  },

  mounted() {
    for (const color of this.colors) {
      if (isEqual(color, this.selectedColor)) {
        return;
      }
    }

    Object.assign(this.customColorConfig, {
      primary: this.selectedColor.primary,
      secondary: this.selectedColor.secondary,
    });
  },

  methods: {
    isEqual,

    updateCustomColorConfig(key, value) {
      this.customColorConfig = {
        ...this.customColorConfig,
        [key]: value,
      };

      this.$emit('input', this.customColorConfig);
    },
  },
};
</script>
