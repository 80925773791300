<template>
  <div class="step step--wide step--big-spaced">
    <div class="domaines-settings__block">
      <h2 class="title--1 title--little-spaced">
        Quels sont vos domaines
        <br />
        d’intervention ?
      </h2>

      <p class="text text--hint">
        Vous pouvez ajouter les domaines d’intervention de votre cabinet juste ici.
        <br />Vous avez la possibilité d’ajouter un domaine qui résulte d’une
        <br />pratique professionnelle effective et habituelle en appuyant sur le
        bouton <strong>“Ajouter un domaine”</strong>
      </p>
      <p class="text text--hint">
        Si vous ne souhaitez pas communiquer sur vos domaines d’intervention,<br />
        merci de ne rien compléter et de passer directement à l’étape suivante.
      </p>

      <p class="text text--hint">
        <strong>
          Si vous intervenez dans une (des) industrie(s) ou secteur(s) bien
          particulier(s),<br />n'hésitez pas à bien le(s) mentionner (domaine
          médical, pénal, industrie agro-alimentaire, commerce, …)</strong
        ><br />
        Nous vous invitons également à mentionner dans cette partie votre typologie
        de clients<br />si celle-ci est spécifique (entreprises, professionnels,
        particuliers, institutions, associations…)
      </p>

      <div class="domains__list">
        <div
          v-for="(intervention, key) in interventions"
          :key="key"
          class="domains__list__item"
        >
          <input
            type="text"
            class="input input--medium"
            placeholder="Domaine d'intervention"
            :value="intervention"
            :class="{ '--empty': intervention === '' }"
            @change="checkIntervention($event, key)"
          />
          <img src="@/assets/svg/Check.svg" class="speciality__checkmark" alt="" />
          <button
            class="button button--delete button--xx-small"
            @click="suppression(key)"
          >
            Supprimer
          </button>
        </div>
        <button
          class="button button--invalid button--x-small"
          @click="addIntervention()"
        >
          Ajouter un domaine
        </button>
      </div>

      <div v-if="interventions.length > 0" class="domains__descriptions">
        <h2 class="title--3">
          <strong>Décrivez en quelques mots</strong><br />
          vos domaines d'intervention
        </h2>
        <div class="descriptions">
          <div
            v-for="(intervention, key) in interventions"
            :key="key"
            class="speciality__description"
          >
            <label :for="`specialities__descripton_${key}`">
              {{ intervention }}
            </label>
            <textarea
              :id="`specialities__descripton_${key}`"
              class="input input--textarea description__input"
              rows="20"
              cols="20"
              :value="customTexts[intervention]"
              placeholder="Informations supplémentaires"
              @change="checkCustomText($event, intervention)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      options: [],
      specialities: [],
      customTexts: [],
      interventions: [],
    };
  },

  computed: {
    ...mapGetters({
      getOfficeSpecialities: 'office/getSpecialities',
      getOfficeInterventions: 'office/getInterventions',
      getOfficeCustomTexts: 'office/getCustomTexts',
      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    specialities(specialities) {
      if (!this.$v.specialities.$invalid) {
        this.updateOfficeSpecialities(specialities);
      }
    },
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  async created() {
    const { data } = await api.get('/specialities');
    this.options = data['hydra:member'];
    this.specialities = this.getOfficeSpecialities;
    this.interventions = this.getOfficeInterventions;
    this.customTexts = this.getOfficeCustomTexts;

    const idAuth = this.getId;
    await api.put('/users/' + idAuth, { LastStep: 'StepSpecialities' });
  },

  methods: {
    ...mapActions({
      updateOfficeSpecialities: 'office/updateSpecialities',
      updateOfficeInterventions: 'office/updateInterventions',
      updateOfficeCustomTexts: 'office/updateCustomTexts',
    }),
    getSpecialtyName(id) {
      let i = 0;
      while (i < this.options.length) {
        if (this.options[i]['@id'] == id) {
          return this.options[i].name;
        }
        i++;
      }
      return '';
    },
    suppression(id) {
      this.interventions.splice(id, 1);
      this.updateInterventions();
    },

    addIntervention() {
      this.interventions.push('');
      this.updateInterventions();
    },

    checkCustomText(e, speciality_id) {
      this.customTexts[speciality_id] = e.currentTarget.value;
      this.updateOfficeCustomTexts(this.customTexts);
    },
    checkIntervention(e, key) {
      e.currentTarget.value
        ? e.currentTarget.classList.remove('--empty')
        : e.currentTarget.classList.add('--empty');
      this.interventions[key] = e.currentTarget.value;
      this.interventions = [...this.interventions];
      this.updateInterventions();
    },

    updateInterventions() {
      this.updateOfficeInterventions(
        this.interventions.filter(intervention => intervention !== '')
      );
    },

    checkSpeciality(e) {
      let max_specialities = 2;
      if (this.specialities.length >= max_specialities) {
        e.preventDefault();
        e.currentTarget.checked = false;
        this.specialities = this.specialities.slice(0, max_specialities);
        return false;
      }
    },
  },

  validations: {
    specialities: {
      maxLength: maxLength(2),
    },
  },
};
</script>
