<template>
  <div class="global-settings__block settings-block-visibility">
    <p class="text text--instruction">
      VISIBILITÉ DE VOTRE SITE INTERNET
    </p>

    <div class="settings-block-visibility__input">
      <img src="@/assets/svg/icon-invisible.svg" class="icon icon--medium" alt="" />

      <label>
        <p-check
          :checked="isSiteVersionInvisible"
          @change="updateSiteVersionInvisible"
        />

        <span class="text">
          Cocher cette case
          <span class="text--bold text--underline">si vous ne souhaitez pas</span>
          que votre site soit référencé et visible sur les moteurs de recherche
          (Google, Bing, Yahoo etc...)
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import PCheck from 'pretty-checkbox-vue/check';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { 'p-check': PCheck },

  computed: {
    ...mapGetters({
      isSiteVersionInvisible: 'siteVersion/isInvisible',
    }),
  },

  methods: {
    ...mapActions({
      updateSiteVersionInvisible: 'siteVersion/updateInvisible',
    }),
  },
};
</script>
