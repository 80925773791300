<template>
  <div class="Page posts--archive">
    <div class="Page__header">
      <div class="content">
        <div class="back">
          <router-link :to="{ name: 'Posts' }">
            Retour à mes articles
          </router-link>
        </div>
        <h1 class="title">
          Gérer mon article
        </h1>
        <p>
          Cet espace est dédié à la gestion du contenu de votre article.
        </p>
      </div>
    </div>
    <div v-if="isLoading" class="simple__loader">
      <CircularLoader />
    </div>
    <div v-if="!isLoading" class="Page__content">
      <div class="container">
        <form class="post-form" @submit.prevent="editpost">
          <div class="form-group">
            <label for="input-firstname">Titre</label>
            <input
              id="input-firstname"
              v-model="post.title"
              type="text"
              class="input input--font-large"
              placeholder="Titre"
            />
            <div v-if="errors.title != ''" style="color: red;">
              {{ errors.title }}
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label for="input-date">Date</label>
              <input
                id="input-date"
                v-model="post.date"
                type="date"
                class="input"
                placeholder="Nom"
                value="post.date"
              />
              <div v-if="errors.date != ''" style="color: red;">
                {{ errors.date }}
              </div>
            </div>
            <div class="form-group">
              <label for="input-email">Statut</label>
              <select
                v-model="post.status"
                name="status"
                class="input input--select"
              >
                <option value="draft">Brouillon</option>
                <option value="publish">Publié</option>
              </select>
            </div>
          </div>

          <div v-if="imageUrl" class="post-visual-preview">
            <div class="img__container">
              <img ref="logoImg" alt="" :src="imageUrl" class="image-input__image" />
            </div>
            <a class="link" @click="removeImage">Supprimer l'image</a>
          </div>
          <div v-else class="image-modal__input" @click="openFileExplorer">
            <input
              ref="fileInput"
              type="file"
              accept=".jpg, .jpeg, .png"
              @change="uploadImage($event.target.files[0])"
            />
            <div class="image-modal__input__content">
              <img src="@/assets/svg/upload.svg" alt="" />
              <p class="link">Importez une image</p>
            </div>
          </div>

          <div v-if="pdfUrl" class="pdf-modal__input">
            <div class="pdf-modal__input__preview">
              <a
                ref="logoPdf"
                alt=""
                :href="pdfUrl"
                class="pdf-input__pdf"
                download
                target="_blank"
              >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path
                    d="M22.4258 16.1934H18.4258V23.1934"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.4258 20.1934H18.4258"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.42578 21.1934H3.42578C4.08882 21.1934 4.72471 20.93 5.19355 20.4611C5.66239 19.9923 5.92578 19.3564 5.92578 18.6934C5.92578 18.0303 5.66239 17.3944 5.19355 16.9256C4.72471 16.4568 4.08882 16.1934 3.42578 16.1934H1.42578V23.1934"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.42578 16.1934V23.1934H11.4258C12.354 23.1934 13.2443 22.8246 13.9007 22.1682C14.557 21.5119 14.9258 20.6216 14.9258 19.6934C14.9258 18.7651 14.557 17.8749 13.9007 17.2185C13.2443 16.5621 12.354 16.1934 11.4258 16.1934H9.42578Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.42578 11.1934V2.19336C1.42578 1.92814 1.53114 1.67379 1.71867 1.48625C1.90621 1.29872 2.16056 1.19336 2.42578 1.19336H14.4258L21.4258 8.19336V11.1934"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.4258 1.19336V8.19336H21.4258"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>Voir le fichier</span>
              </a>
              <a class="link" @click="removePdf">Supprimer le fichier</a>
            </div>
          </div>
          <div v-else class="pdf-modal__input" @click="openPdfExplorer">
            <input
              ref="pdfInput"
              type="file"
              accept=".pdf"
              @change="uploadPdf($event.target.files[0])"
            />
            <div class="pdf-modal__input__content">
              <img src="@/assets/svg/upload.svg" alt="" />
              <p class="link">Importez un pdf</p>
            </div>
          </div>

          <div class="form-group">
            <label for="input-lastname">Contenu</label>

            <VueEditor
              v-model="post.content"
              :editor-toolbar="customToolbar"
            ></VueEditor>
            <div v-if="errors.content != ''" style="color: red;">
              {{ errors.content }}
            </div>
          </div>

          <div class="form-group">
            <label for="input-url">Url de l'article</label>
            <input
              id="input-url"
              v-model="post.name"
              type="text"
              class="input"
              placeholder="Url de l'article"
            />
            <div v-if="errors.name != ''" style="color: red;">{{ errors.name }}</div>
            <span class="url_preview">
              {{ `https://${getDomainName}/article/${post.name}` }}
            </span>
          </div>

          <div v-if="!isLoading" class="actions">
            <button class="button post-form__cta" type="submit">
              {{ post.id ? 'Enregistrer' : 'Créer mon article' }}
            </button>
            <button
              v-if="post.id"
              class="button button--remove post-form__cta"
              @click="deletepost(post)"
            >
              <span>Supprimer</span>
              <svg
                clip-rule="evenodd"
                fill-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapGetters } from 'vuex';
import { VueEditor } from 'vue2-editor';
import CircularLoader from '@/components/common/CircularLoader.vue';

export default {
  components: { CircularLoader, VueEditor },

  data() {
    return {
      isLoading: false,
      imageUrl: null,
      pdfUrl: null,
      errors: {
        title: '',
        date: '',
        content: '',
        name: '',
      },
      post: {
        id: undefined,
        title: '',
        date: '',
        content: '',
        name: '',
        status: 'draft',
        media: '',
        pdf: '',
      },
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ['bold', 'italic'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['blockquote'],
        ['link', 'image'],
      ],
    };
  },

  computed: {
    ...mapGetters({
      getId: 'auth/getId',
      getSite: 'site/getState',
      getDomainName: 'site/getDomainName',
    }),
  },

  async created() {
    this.title = '';
    if (this.$route.params.id) {
      this.isLoading = true;
      const { data: post } = await api.get(`/posts/${this.$route.params.id}`);
      this.isLoading = false;
      this.post = post;
      let v = this.post.date.search('T');
      this.post.date = this.post.date.substring(0, v);
      this.imageUrl = this.post.media;
      this.pdfUrl = this.post.pdf;
      this.title = `${this.post.title}`;
    }
  },

  methods: {
    openFileExplorer() {
      this.$refs.fileInput.click();
    },

    openPdfExplorer() {
      this.$refs.pdfInput.click();
    },

    async uploadImage(file) {
      this.image = file;

      if (!file.size) {
        return;
      }

      const formData = new FormData();
      formData.append('file', file, this.imageName);

      console.log('call api');

      const { data } = await api.post('/images', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.imageUrl = data.contentUrl;
      this.post.media = data.contentUrl;
      this.selectedImageUrl = data.contentUrl;
    },

    async uploadPdf(file) {
      this.pdf = file;

      if (!file.size) {
        return;
      }

      const formData = new FormData();
      formData.append('file', file, this.imageName);

      console.log('call api');
      const { data } = await api.post('/pdfs', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.pdfUrl = data.contentUrl;
      this.post.pdf = data.contentUrl;
    },

    deletepost(post) {
      if (confirm('Êtes vous sûr de vouloir de supprimer ce post ?')) {
        api.delete(`/posts/${post.id}`);
        setTimeout(() => {
          this.$router.push({ name: 'Posts' });
        }, 1000);
      }
    },
    editpost() {
      this.post.site = '/sites/' + this.getSite.id;
      let validated = true;
      if (this.post.content == '') {
        this.errors.content = 'Le champs contenu est requis';
        validated = false;
      } else {
        this.errors.content = '';
      }
      if (this.post.date == '') {
        this.errors.date = 'Le champs date est requis';
        validated = false;
      } else {
        this.errors.date = '';
      }
      if (this.post.name == '') {
        this.errors.name = 'Le champs url est requis';
        validated = false;
      } else {
        this.errors.name = '';
      }
      if (this.post.title == '') {
        this.errors.title = 'Le champs titre est requis';
        validated = false;
      } else {
        this.errors.title = '';
      }
      if (validated == false) {
        return 0;
      }
      this.isLoading = true;
      if (this.post.date == '') {
        this.post.date = null;
      }
      if (this.post.id == undefined) {
        api.post(`/posts`, this.post);
      } else {
        console.log(this.post);
        api.put(`/posts/${this.post.id}`, this.post);
      }
      setTimeout(() => {
        this.$router.push({ name: 'Posts' });
      }, 1000);
    },

    removeImage() {
      this.imageUrl = '';
      this.post.media = '';
    },
    removePdf() {
      this.pdfUrl = '';
      this.post.pdf = '';
    },
  },

  validations: {
    image: {
      fileSize: image => {
        return image.size < 2000000;
      },
    },
    pdf: {
      fileSize: pdf => {
        return pdf.size < 2000000;
      },
    },
  },
};
</script>
