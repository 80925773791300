var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{class:[
        'modal-container',
        ("modal-container--" + _vm.width),
        { 'modal-container--padding-top': _vm.paddingTop } ]},[(_vm.closeButton)?_c('button',{staticClass:"modal-close-button",on:{"click":_vm.requestClose}},[_c('img',{attrs:{"src":require("@/assets/svg/close.svg")}})]):_vm._e(),(_vm.icon)?_c('div',{staticClass:"modal-icon"},[_c('img',{attrs:{"src":require("@/assets/svg/Check.svg")}})]):_vm._e(),_c('div',{class:[
          'modal-header',
          {
            'modal-header--padding-top': _vm.paddingTop,
          } ]},[_vm._t("header")],2),_c('div',{staticClass:"modal-body"},[_vm._t("body")],2),_c('div',{staticClass:"modal-footer"},[_vm._t("footer")],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }