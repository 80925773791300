<template>
  <div>
    <!-- <CircularLoader v-if="isLoading" />-->
    <div v-if="user.site" class="dashboard">
      <Toastrs />
      <header class="dashboard__header">
        <figure class="dashboard__header__logo">
          <router-link :to="{ name: 'DashboardHome' }">
            <img src="@/assets/svg/logo-solency.svg" alt="Logo Solency" />
          </router-link>

          <button class="burger" @click.prevent="toggleMenu">
            <span>Menu</span>
            <div></div>
            <div></div>
            <div></div>
          </button>
        </figure>

        <nav class="dashboard__header__tabs" aria-label="Navigation principale">
          <ul>
            <li class="has-submenu">
              <router-link
                class="dashboard__header__tabs__tab"
                :to="{ name: 'SiteSettings' }"
              >
                <span class="site-settings__visibility-icon">
                  <div v-if="isSiteVersionInvisible || !isPublished" class="offline">
                    <span class="state">Hors ligne</span>
                  </div>
                  <div v-else class="online">
                    <span class="state">En ligne</span>
                  </div>
                </span>
                <span>Mon Site</span>
              </router-link>

              <ul class="submenu">
                <li>
                  <router-link
                    class="dashboard__header__tabs__tab"
                    :to="{ name: 'DashboardHome' }"
                  >
                    Accueil
                  </router-link>
                </li>
                <li v-if="isPublished">
                  <router-link
                    class="dashboard__header__tabs__tab"
                    :to="{ name: 'SiteSettings', hash: 'LiveEdit' }"
                  >
                    Prévisualiser mon site
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="dashboard__header__tabs__tab"
                    :to="{ name: 'Appointments' }"
                  >
                    Rendez-vous
                  </router-link>
                </li>
                <li>
                  <!--v-if="getSiteVersionStatus === 'PROD'"-->
                  <router-link
                    class="dashboard__header__tabs__tab"
                    :to="{ name: 'Stats' }"
                  >
                    Performances
                  </router-link>
                </li>

                <li>
                  <router-link
                    :class="[
                      'dashboard__header__tabs__tab',
                      {
                        badge: unreadMessages,
                      },
                    ]"
                    :to="{ name: 'Mailbox' }"
                  >
                    Messages
                  </router-link>
                </li>
              </ul>
            </li>

            <li>
              <router-link
                class="dashboard__header__tabs__tab"
                :to="{ name: 'Posts' }"
              >
                Mon blog
              </router-link>
            </li>

            <li>
              <a
                class="dashboard__header__tabs__tab"
                :href="eLearningUrl"
                target="_blank"
              >
                E-learning
              </a>
            </li>
            <li>
              <a
                class="dashboard__header__tabs__tab"
                :href="
                  `https://formations.solency.com/base-de-connaissances-solency${getToken}`
                "
                target="_blank"
              >
                Base de connaissances
              </a>
            </li>
            <li>
              <a
                class="dashboard__header__tabs__tab"
                :href="`https://marketplace.solency.com/${getToken}`"
                target="_blank"
              >
                Marketplace
              </a>
            </li>
            <!--<li>
              <a
                class="dashboard__header__tabs__tab"
                href="#"
                target="_blank"
                rel="noreferrer noopener"
              >
                Mon tableau de bord
              </a>
            </li>
            -->
          </ul>
        </nav>
        <div class="dashboard__header__user-info">
          <div class="dashboard__header__user-info__name">
            <router-link :to="{ name: 'MyAccount' }">
              {{ user.firstname }}
              {{ user.lastname }}
            </router-link>
            <div class="submenu">
              <ul>
                <li>
                  <router-link
                    class="dashboard__header__tabs__tab"
                    :to="{ name: 'MyAccount' }"
                  >
                    Mon compte
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <router-link
            :to="{ name: 'MyAccount' }"
            class="dashboard__header__user-info__avatar"
          >
            <img src="@/assets/images/octopus-profile.svg" alt="Poulpe" />
          </router-link>
          <a class="dashboard__header__user-info__logout-link" @click="logout">
            Déconnexion
          </a>
        </div>
      </header>
      <div class="dashboard__body">
        <router-view
          :stats="stats"
          :messages="messages"
          :unread-messages="unreadMessages"
          @mark-read="unreadMessages = false"
        ></router-view>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';
import Footer from '@/components/Footer';
// import CircularLoader from '@/components/common/CircularLoader';
import Toastrs from '@/components/common/Toastrs';

export default {
  components: { Toastrs, Footer },
  data() {
    return {
      stats: {},
      user: {},
      messages: [],
      unreadMessages: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      getSiteVersionContent: 'siteVersion/getContent',
      getSiteVersionSite: 'siteVersion/getSite',
      getSiteVersionStatus: 'siteVersion/getStatus',
      getIsFirstVersionPublished: 'site/getIsFirstVersionPublished',
      getSite: 'site/getState',
      getSiteId: 'site/getId',
      getId: 'auth/getId',
      getOffice: 'office/getState',
      eLearningUrl: 'user/getELearningUrl',
      isSiteVersionInvisible: 'siteVersion/isOnLine',
    }),
    getToken() {
      const token = this.eLearningUrl.split('https://formations.solency.com')[1];
      return token;
    },
    isPublished() {
      return this.getIsFirstVersionPublished;
    },
  },

  async created() {
    this.isLoading = true;

    const idAuth = this.getId;
    const { data: user } = await api.get(`/users/${idAuth}`);

    this.user = user;
    this.updateUser(user);

    this.isLoading = false;

    if (!user.site) {
      return this.$router.push({ name: 'Welcome' });
    }

    if (!this.getSite.id) {
      const { data: sites } = await api.get(`${user.site}`);
      this.updateSite(sites);
    }

    const { id } = this.getSite;
    const { data: site } = await api.get(`/sites/${id}`);
    this.updateSite(site);
    const { data } = await api.get(
      site.draftSiteVersion || site.productionSiteVersion
    );

    this.updateAllFieldsFirstly(data);
    this.updateOffice(data.site.office);
    if (data.site.office.specialities) {
      let names = [];
      data.site.office.specialities.forEach(speciality => {
        names.push(speciality['@id']);
      });
      this.updateOfficeSpecialities(names);
    }

    const { data: stats } = await api.get(`/sites/${id}/performance`);
    this.stats = stats;

    const { data: messages } = await api.get('/messages', {
      params: {
        site: this.getSiteId,
      },
    });

    this.messages = messages['hydra:member'];

    this.unreadMessages = this.hasUnreadMessages();
  },

  methods: {
    ...mapActions({
      updateSiteVersion: 'siteVersion/updateAllFields',
      updateAllFieldsFirstly: 'siteVersion/updateAllFieldsFirstly',
      updateSite: 'site/updateSite',
      updateOffice: 'office/updateOffice',
      updateCoworkers: 'office/updateCoworkers',
      authLogout: 'auth/logout',
      updateUser: 'user/updateUser',
      addToastr: 'toastr/add',
      updateOfficeSpecialities: 'office/updateSpecialities',
    }),

    async logout() {
      await this.authLogout();

      window.location.href = 'https://www.solency.com/';
    },

    hasUnreadMessages() {
      return this.messages.some(message => !message.read);
    },

    toggleMenu(event) {
      const button = event.currentTarget;
      const nav = button.parentNode.parentNode.querySelector('nav');

      button.classList.toggle('is--active');
      nav.classList.toggle('show--nav');
    },
  },
};
</script>
