<template>
  <div></div>
</template>

<script>
import { api } from '@/lib/api';

export default {
  components: {},

  data() {
    return {};
  },

  watch: {},

  async created() {
    if (this.$route.query.t) {
      this.isLoading = true;
      try {
        const { data } = await api.get('/sites/' + this.$route.query.t);
        window.location.href = data.hostingSettings.draft.url;
      } catch {
        console.log('');
      }
    }
    if (this.$route.query.externalId) {
      this.isLoading = true;
      try {
        const { data } = await api.post(
          '/site_preview?externalId=' + this.$route.query.externalId
        );
        window.location.href = data.hostingSettings.draft.url;
      } catch {
        console.log('');
      }
    }
  },

  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content:
            'Connectez-vous à votre compte Solency afin de gérer votre site web pour votre cabinet d’avocats.' +
            ' Découvrez aussi la marketplace et nos partenaires qui vous aideront à trouver des clients' +
            ' et digitaliser votre cabinet.',
        },
      ],
    };
  },
};
</script>
