<template>
  <div class="appointments-settings__time-slot-duration">
    <span class="text">Chaque nouveau créneau de rendez-vous aura une durée de</span>
    <div class="time-slot-duration__select-wrapper">
      <img
        v-if="loading"
        src="@/assets/images/loader.gif"
        alt=""
        class="time-slot-duration__loader"
      />
      <v-select
        v-else
        v-model="selectedTimeSlotDuration"
        class="time-slot-duration__select"
        label="label"
        :options="validTimeSlotDurations"
        :searchable="false"
        :clearable="false"
        :disabled="!isAppointmentsModuleEnabled"
        @input="postTimeSlotDuration"
      />
    </div>
    <span v-if="showSaveMessage" class="text text--validation">
      Modification sauvegardée
      <i class="icon mdi mdi-18px mdi-check"></i>
    </span>
  </div>
</template>

<script>
import VueSelect from 'vue-select';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { 'v-select': VueSelect },

  props: ['schedule'],

  data() {
    return {
      validTimeSlotDurations: [
        { label: '30 minutes', value: 0.5 },
        { label: '45 minutes', value: 0.75 },
        { label: '1 heure', value: 1 },
        { label: '2 heures', value: 2 },
        { label: '4 heures', value: 4 },
      ],
      selectedTimeSlotDuration: null,
      loading: false,
      showSaveMessage: false,
      timeout: null,
    };
  },

  computed: {
    ...mapGetters({
      isAppointmentsModuleEnabled: 'coworkers/appointmentsModuleEnabled',
    }),
  },

  watch: {
    schedule(oldSchedule, newSchedule) {
      if (oldSchedule.id !== newSchedule.id) {
        this.refreshTimeSlotDuration();
      }
    },
  },

  mounted() {
    this.refreshTimeSlotDuration();
  },

  methods: {
    ...mapActions({
      updateTimeSlotDuration: 'schedule/updateTimeSlotDuration',
    }),

    async postTimeSlotDuration() {
      this.loading = true;

      await this.updateTimeSlotDuration(this.selectedTimeSlotDuration.value);

      this.loading = false;
      this.showSaveMessage = true;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => (this.showSaveMessage = false), 3000);
    },

    refreshTimeSlotDuration() {
      this.selectedTimeSlotDuration = this.validTimeSlotDurations.filter(
        validTimeSlotDuration => {
          return validTimeSlotDuration.value === this.schedule.timeSlotDuration;
        }
      );
    },
  },
};
</script>
