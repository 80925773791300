<template>
  <div class="appointments-types">
    <div class="appointments-types__checkbox-wrapper">
      <p-check
        v-model="inPersonAppointments"
        class="appointments-types__checkbox p-icon p-smooth"
        :disabled="!isAppointmentsModuleEnabled"
      >
        <i slot="extra" class="icon mdi mdi-check" />
        Au cabinet
      </p-check>
    </div>

    <div class="appointments-types__checkbox-wrapper">
      <p-check
        v-model="videoCallsAppointments"
        class="appointments-types__checkbox p-icon p-smooth"
        :disabled="!isAppointmentsModuleEnabled"
      >
        <i slot="extra" class="icon mdi mdi-check" />
        En visioconférence
      </p-check>
    </div>

    <div class="appointments-types__checkbox-wrapper">
      <p-check
        v-model="phoneCallsAppointments"
        class="appointments-types__checkbox p-icon p-smooth"
        :disabled="!isAppointmentsModuleEnabled"
      >
        <i slot="extra" class="icon mdi mdi-check" />
        Par téléphone
      </p-check>
    </div>
  </div>
</template>

<script>
import PrettyCheck from 'pretty-checkbox-vue/check';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { 'p-check': PrettyCheck },
  props: {
    schedule: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      isAppointmentsModuleEnabled: 'coworkers/appointmentsModuleEnabled',
    }),

    inPersonAppointments: {
      get() {
        return this.schedule.inPersonAppointments;
      },

      set(inPersonAppointments) {
        this.updateInPersonAppointments(inPersonAppointments);
      },
    },

    videoCallsAppointments: {
      get() {
        return this.schedule.videoCallsAppointments;
      },

      set(videoCallsAppointments) {
        this.updateVideoCallsAppointments(videoCallsAppointments);
      },
    },

    phoneCallsAppointments: {
      get() {
        return this.schedule.phoneCallsAppointments;
      },

      set(phoneCallsAppointments) {
        this.updatePhoneCallsAppointments(phoneCallsAppointments);
      },
    },
  },

  methods: {
    ...mapActions({
      updateInPersonAppointments: 'schedule/updateInPersonAppointments',
      updatePhoneCallsAppointments: 'schedule/updatePhoneCallsAppointments',
      updateVideoCallsAppointments: 'schedule/updateVideoCallsAppointments',
    }),
  },
};
</script>
