<template>
  <div class="step step--spaced">
    <h1 class="title--1 title--little-spaced">
      Quelles informations souhaitez-vous nous transmettre concernant votre cabinet
      d’avocat ?
    </h1>
    <p class="text text--hint">
      Cette description sera utilisée sur votre site, soyez concis et clair.
      <br />
      La description doit avoir <strong>un minimum de 50 caractères</strong>.
    </p>

    <p class="text text--hint">
      N’hésitez pas à
      <strong>mentionner vos spécialités et secteurs d’interventions</strong>, les
      réexpliquer avec d’autres mots pour favoriser la bonne compréhension de votre
      activité par tous les utilisateurs. <br />
      N’hésitez pas à mentionner les "problèmes" que vos utilisateurs ou futurs
      clients pourraient rencontrer, et
      <strong>sur lesquels vous pouvez les aider</strong> :
      litiges/contentieux/divorce/plaintes/intérêts/différend/mise en
      demeure/injonction/assignation/recours/médiation/…
    </p>

    <div class="description">
      <textarea
        v-model="siteVersionDescription"
        class="input input--textarea description__input"
        :maxlength="descriptionMaxLength"
        :placeholder="
          'Vous pouvez indiquer ici une courte description, n\'hésitez pas à mentionner :\n- Structure d\'exercice\n- Réseau dont le cabinet est membre'
        "
      />

      <p class="text text--validation">
        {{ remainingCharacters }} CARACTÈRES RESTANTS
      </p>
    </div>

    <p class="text text--hint">
      Vous pouvez également détailler tous
      <strong>les types de prestations</strong> : conseil, rédaction de contrat,
      juriste, procédure judiciaire, recouvrement, protection des droits, En résumé,
      comment pourriez-vous aider l’utilisateur qui arrive sur votre site ?
      <br />Nous vous conseillons également de
      <strong>
        mentionner les tribunaux, instances, ou juridictions auxquels vous êtes
        rattaché(s)</strong
      >.
    </p>

    <div class="fees">
      <textarea
        v-model="siteVersionFees"
        class="input input--textarea fees__input"
        :maxlength="feesMaxLength"
        :placeholder="
          'Le CNB préconise de mentionner les informations suivantes : \n- Le montant des honoraires. \n- Le mode de détermination des honoraires \n- Les modes de facturation des honoraires. \n- possibilité de saisir le bâtonnier pour contester les honoraires (articles 174 et suivants du décret du 27 novembre 1991) \n- La possibilité de recourir à un médiateur de la consommation'
        "
      />
      <p class="text text--validation">
        {{ remainingFeesCharacters }} CARACTÈRES RESTANTS
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { api } from '@/lib/api';

export default {
  data() {
    return {
      descriptionMaxLength: 800,
      feesMaxLength: 800,
      siteVersionDescription: '',
      siteVersionFees: '',
    };
  },

  computed: {
    ...mapGetters({
      getSiteVersionDescription: 'siteVersion/getDescription',
      getSiteVersionFees: 'siteVersion/getFees',
      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),

    remainingCharacters() {
      return this.descriptionMaxLength - this.siteVersionDescription.length;
    },

    remainingFeesCharacters() {
      return this.feesMaxLength - this.siteVersionFees.length;
    },
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    siteVersionDescription(description) {
      if (!this.$v.siteVersionDescription.$invalid) {
        this.updateSiteVersionDescription(description);
      }
    },

    siteVersionFees(description) {
      if (!this.$v.siteVersionFees.$invalid) {
        this.updateSiteVersionFees(description);
      }
    },
  },

  created() {
    this.siteVersionDescription = this.getSiteVersionDescription;
    this.siteVersionFees = this.getSiteVersionFees;

    if (typeof this.siteVersionDescription == 'undefined') {
      this.siteVersionDescription = '';
    }
    if (typeof this.siteVersionFees == 'undefined') {
      this.siteVersionFees = '';
    }

    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepDescription' });
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  methods: {
    ...mapActions({
      updateSiteVersionDescription: 'siteVersion/updateDescription',
      updateSiteVersionFees: 'siteVersion/updateFees',
    }),
  },

  validations() {
    return {
      siteVersionDescription: {
        required,
        minLength: minLength(50),
        maxLength: maxLength(this.descriptionMaxLength),
      },
      siteVersionFees: {
        minLength: minLength(50),
        maxLength: maxLength(this.feesMaxLength),
      },
    };
  },
};
</script>
