<template>
  <div class="step">
    <img src="@/assets/svg/Check.svg" alt="" class="check-icon--large" />
    <h1>
      Félicitations, votre <br />
      site est prêt !
    </h1>
    <router-link class="button" :to="{ name: 'DashboardHome' }">
      JE FINALISE LA CRÉATION DE SITE
    </router-link>
    <router-link class="link" target="_blank" :to="{ name: 'DashboardHome' }">
      Continuer vers votre espace Solency
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getSiteVersionSite: 'siteVersion/getSite',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  created() {
    this.updateShowFirstCreationModal(true);
  },

  methods: {
    ...mapActions({
      updateShowFirstCreationModal: 'settings/updateShowFirstCreationModal',
    }),
  },
};
</script>

<style lang="scss" scoped>
.link {
  margin-top: 20px;
}
</style>
