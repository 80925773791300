<template>
  <div class="step">
    <h1 class="title--1 title--little-spaced">
      Quels sont les contacts <br />
      principaux de votre cabinet ?
    </h1>
    <p class="text text--hint text--with-margin-bottom">
      Votre <strong>numéro de téléphone</strong> et
      <strong>votre adresse mail</strong> seront indiqués sur votre site<br />
      pour que les internautes puissent vous contacter.<br />
      Vous pourrez modifier ces informations depuis votre espace
      <strong>« Gestion de mon site »</strong>.<br /><br />
      <strong>A noter :</strong> Lorsqu’un internaute vous contacte via le formulaire
      de contact de votre site, vous recevrez automatiquement son message sur
      l'adresse mail que vous avez renseignée. Vous pourrez également retrouvez son
      message depuis votre espace personnel Solency.
      <br />
      De son côté, l’internaute recevra une confirmation d’envoi de son message par
      email.
    </p>
    <div class="form-row">
      <input
        v-model="officePhoneNumber"
        type="text"
        class="input input--medium"
        placeholder="Numéro de téléphone"
      />
      <input
        v-model="officeEmail"
        type="text"
        class="input input--medium"
        placeholder="Contact email principal"
      />
    </div>
    <p
      v-if="$v.officePhoneNumber.required && $v.officePhoneNumber.$invalid"
      class="text text--not-available"
    >
      Le numéro de téléphone que vous avez saisi est incorrect. Merci de vérifier.
    </p>
    <p
      v-if="$v.officeEmail.required && $v.officeEmail.$invalid"
      class="text text--not-available"
    >
      L'adresse email que vous avez saisie est incorrecte. Merci de vérifier.
    </p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  required,
  email,
  maxLength,
  minLength,
  numeric,
} from 'vuelidate/lib/validators';
import { api } from '@/lib/api';

export default {
  data() {
    return {
      officePhoneNumber: '',
      officeEmail: '',
    };
  },

  computed: {
    ...mapGetters({
      getOfficeAddress: 'office/getAddress',
      getOfficeEmail: 'office/getEmail',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    officePhoneNumber(value) {
      if (!this.$v.officePhoneNumber.$invalid) {
        this.updateOfficeAddress({ phoneNumber: value });
      }
    },

    officeEmail(value) {
      if (!this.$v.officeEmail.$invalid) {
        this.updateOfficeEmail(value);
      }
    },
  },

  created() {
    this.officePhoneNumber = this.getOfficeAddress.phoneNumber;
    this.officeEmail = this.getOfficeEmail;

    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepContact' });
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  methods: {
    ...mapActions({
      updateOfficeAddress: 'office/updateAddress',
      updateOfficeEmail: 'office/updateEmail',
    }),
  },

  validations: {
    officePhoneNumber: {
      required,
      numeric,
      maxLength: maxLength(10),
      minLength: minLength(10),
    },

    officeEmail: {
      required,
      email,
    },
  },
};
</script>
