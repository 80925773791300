<template>
  <div class="step step--wide step--big-spaced">
    <h1 class="title--1">
      Parfait. <br />
      Avez-vous, vous ou votre cabinet,
      <br />des réseaux sociaux actifs ?
    </h1>
    <p class="text text--instruction">QUELS RÉSEAUX SOCIAUX UTILISEZ-VOUS ?</p>

    <SocialNetworksInput
      :values="getSiteVersionSocialNetworks"
      @input="updateSiteVersionSocialNetworks($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SocialNetworksInput } from '@/components';
import { api } from '@/lib/api';

export default {
  components: { SocialNetworksInput },

  computed: {
    ...mapGetters({
      getSiteVersionSocialNetworks: 'siteVersion/getSocialNetworks',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  methods: {
    ...mapActions({
      updateSiteVersionSocialNetworks: 'siteVersion/updateSocialNetworks',
    }),
  },

  // eslint-disable-next-line vue/order-in-components
  created() {
    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepSocialNetwork' });
  },
};
</script>
