<template>
  <div class="settings-block">
    <p class="text text--instruction">
      QUI SONT LES AVOCATS DE VOTRE CABINET ?
    </p>

    <div
      v-for="(coworker, index) in getOfficeCoworkers"
      :key="index"
      class="settings-block__settings --separate"
    >
      <div class="coworkers form-row">
        <input
          type="text"
          class="input"
          placeholder="Nom"
          :value="coworker.lastname"
          @input="updateOfficeCoworker({ index, lastname: $event.target.value })"
        />
        <input
          type="text"
          class="input"
          placeholder="Prénom"
          :value="coworker.firstname"
          @input="updateOfficeCoworker({ index, firstname: $event.target.value })"
        />
      </div>
      <p class="text text--instruction">
        INFORMATIONS COMPLÉMENTAIRES
      </p>
      <div class="form-row">
        <input
          type="text"
          class="input"
          placeholder="Téléphone"
          :value="coworker.phoneNumber"
          @input="updateOfficeCoworker({ index, phoneNumber: $event.target.value })"
        />
        <input
          type="text"
          class="input"
          placeholder="Email"
          :value="coworker.email"
          @input="updateOfficeCoworker({ index, email: $event.target.value })"
        />
      </div>

      <div class="form-row">
        <input
          type="text"
          class="input"
          placeholder="Barreau"
          :value="coworker.barLaw"
          @input="updateOfficeCoworker({ index, barLaw: $event.target.value })"
        />
      </div>

      <div class="form-row">
        <input
          type="text"
          class="input"
          placeholder="Spécialités"
          :value="coworker.speciality"
          @input="updateOfficeCoworker({ index, speciality: $event.target.value })"
        />
      </div>
      <div class="description">
        <textarea
          class="input input--textarea description__input"
          :value="coworker.description"
          :placeholder="
            `Vous pouvez indiquer ici une courte description sur ${coworker.firstname} ${coworker.lastname}`
          "
          @input="updateOfficeCoworker({ index, description: $event.target.value })"
        />
      </div>
      <SocialNetworksInput
        :values="coworker.socialNetworks"
        @input="updateOfficeCoworkerSocialNetworks({ index, ...$event })"
      />
      <div class="portrait">
        <p class="text text--instruction">
          PHOTO DE PROFIL
        </p>
        <p>
          Nous vous conseillons de redimensionner votre image. Suite au
          téléchargement de votre photo, vous aurez la possibilité d'agrandir, de
          réduire et de déplacer votre photo dans une zone dédiée.
        </p>
        <ProfilePicture :index="index" :coworker="coworker" />
      </div>
      <button
        class="button button--delete button--x-small"
        style="margin-top: 0px;"
        @click="removeOfficeCoworker(index)"
      >
        Supprimer cette personne
      </button>
    </div>
    <button
      class="button button--invalid button--x-small"
      style="margin-top: 40px;"
      @click="addOfficeCoworker"
    >
      Ajouter une personne
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SocialNetworksInput } from '@/components';
import ProfilePicture from './ProfilePicture';

export default {
  components: { ProfilePicture, SocialNetworksInput },

  computed: {
    ...mapGetters({
      getOfficeCoworkers: 'office/getCoworkers',
    }),
  },

  methods: {
    ...mapActions({
      updateOfficeCoworker: 'office/updateCoworker',
      updateOfficeCoworkerSocialNetworks: 'office/updateCoworkerSocialNetworks',
      addOfficeCoworker: 'office/addCoworker',
      removeOfficeCoworker: 'office/removeCoworker',
    }),
  },
};
</script>
