<template>
  <div class="my-appointments">
    <h1 class="appointments__title">
      Mes rendez-vous - {{ coworker.firstname }} {{ coworker.lastname }}
    </h1>

    <section class="my-appointments__section">
      <h2>Demandes en attente</h2>
      <AppointmentsList
        :appointments="requestedAppointments"
        accept-action
        defer-action
        decline-action
        @refresh-appointments-list="refreshAllAppointments"
      />
    </section>

    <section class="my-appointments__section">
      <h2>Prochains rendez-vous</h2>
      <AppointmentsList
        :appointments="acceptedAppointments"
        defer-action
        cancel-action
        show-conference-url
        @refresh-appointments-list="refreshAllAppointments"
      />
      <a
        ref="my-appointments__ics"
        :class="[
          'my-appointments__ics',
          'button',
          'button--x-small',
          { 'button--invalid': isAcceptedAppointmentsEmpty },
          { 'my-appointments__ics--disabled': isAcceptedAppointmentsEmpty },
        ]"
      >
        <span>Ajouter au calendrier</span>
        <img
          src="@/assets/images/calendar.png"
          alt=""
          class="icon my-appointments__ics-icon"
        />
      </a>
    </section>

    <section class="my-appointments__section">
      <h2>Rendez-vous passés</h2>
      <AppointmentsList
        :appointments="passedAppointments"
        @refresh-appointments-list="refreshAllAppointments"
      />
    </section>
  </div>
</template>

<script>
import AppointmentsList from '@/components/dashboard/appointments/my-appointments/AppointmentsList';
import { api } from '@/lib/api';
import { datesMixin } from '@/mixins/datesMixin';
import { mapGetters } from 'vuex';

export default {
  components: { AppointmentsList },

  mixins: [datesMixin],

  props: {
    currentTab: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      defaultItemsPerList: 5,
      requestedAppointments: {},
      acceptedAppointments: {},
      passedAppointments: {},
      apiURL: process.env.VUE_APP_API_URL,
      ics: '',
    };
  },

  computed: {
    ...mapGetters({
      coworker: 'coworkers/getCurrent',
      scheduleId: 'coworkers/currentScheduleId',
    }),

    isAcceptedAppointmentsEmpty() {
      if (!this.acceptedAppointments?.['hydra:totalItems']) {
        return true;
      }
      return this.acceptedAppointments['hydra:totalItems'] === 0;
    },

    icsFileName() {
      return (
        'export-solency-' +
        this.coworker['firstname'] +
        '-' +
        this.coworker['lastname'] +
        '-' +
        this.formattedDateUS(new Date())
      ).toLowerCase();
    },
  },

  watch: {
    async scheduleId(oldScheduleId, newScheduleId) {
      if (oldScheduleId === newScheduleId) {
        return;
      }

      await this.refreshAllAppointments();
      await this.generateICS();
    },

    coworker(oldCoworker, newCoworker) {
      if (oldCoworker === newCoworker) {
        return;
      }
      this.generateICS();
    },
  },

  async created() {
    await this.refreshAllAppointments();
    await this.generateICS();
  },

  methods: {
    async getScheduleAppointments(
      appointmentStatus = null,
      after = null,
      before = null,
      paginated = true
    ) {
      return (
        await api.get(
          this.scheduleId +
            '/appointments?' +
            (paginated
              ? '&paginated=true&itemsPerPage=' + this.defaultItemsPerList
              : '') +
            (appointmentStatus !== null ? '&status=' + appointmentStatus : '') +
            (before !== null ? '&endDatetime[before]=' + before : '') +
            (after !== null ? '&startDatetime[after]=' + after : '')
        )
      ).data;
    },

    async refreshRequestedAppointments() {
      this.requestedAppointments = await this.getScheduleAppointments(
        'REQUESTED',
        null,
        null,
        false
      );
    },

    async refreshAcceptedAppointments() {
      this.acceptedAppointments = await this.getScheduleAppointments(
        'ACCEPTED',
        this.formattedDateUS(new Date()),
        null,
        false
      );
      this.generateICS();
    },

    async refreshPassedAppointments() {
      this.passedAppointments = await this.getScheduleAppointments(
        'ACCEPTED',
        null,
        this.formattedDateUS(new Date()),
        false
      );
    },

    async refreshAllAppointments() {
      await this.refreshRequestedAppointments();
      await this.refreshAcceptedAppointments();
      await this.refreshPassedAppointments();
    },

    async generateICS() {
      if (!this.scheduleId) {
        return '';
      }

      const { data: ics } = await api.get(this.scheduleId + '/ics');

      const icsButton = this.$refs['my-appointments__ics'];

      icsButton.setAttribute(
        'href',
        'data:text/calendar;charset=utf-8,' + encodeURIComponent(ics)
      );

      icsButton.setAttribute('download', this.icsFileName);
    },
  },
};
</script>
