<template>
  <div class="Page posts--archive">
    <div class="Page__header">
      <div class="content">
        <h1 class="title">
          Gérer mon blog
        </h1>
        <p>
          Cet espace est dédié à la gestion de l'ensemble de vos articles et/ou
          actualités.<br />
          Pour commencer, cliquez sur <strong>Ajouter un article</strong>.<br />
          Si vous souhaitez éditer un article existant, cliquez sur
          <strong>Modifier</strong>.
        </p>
      </div>
      <div class="actions">
        <button
          class="button"
          @click="$router.push({ name: 'Post', params: { id: 0 } })"
        >
          Ajouter un article
        </button>
      </div>
    </div>
    <div class="Page__content">
      <div v-if="isLoading" class="simple__loader">
        <CircularLoader />
      </div>
      <div v-else-if="posts.length === 0" class="no__results">
        <p>Aucun article disponible</p>
      </div>
      <div v-else class="Table__responsive">
        <table class="Table" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th
                class="--order"
                :class="{
                  '--ordered': orders.attribute === 'date',
                  '--desc': orders.attribute === 'date' && orders.order === 'desc',
                }"
                @click="changeOrder('date')"
              >
                <span>Date de création</span>
                <span class="arrow">
                  <svg width="8" height="12" viewBox="0 0 7.95 12.44">
                    <path d="M.75,8.41l3.23,3,3.22-3" fill="#31b39f" />
                    <path d="M7.2,4,4,1,.75,4" />
                  </svg>
                </span>
              </th>
              <th>Titre</th>
              <th
                class="--order"
                :class="{
                  '--ordered': orders.attribute === 'status',
                  '--desc': orders.attribute === 'status' && orders.order === 'desc',
                }"
                @click="changeOrder('status')"
              >
                <span>Statut</span>
                <span class="arrow">
                  <svg width="8" height="12" viewBox="0 0 7.95 12.44">
                    <path d="M.75,8.41l3.23,3,3.22-3" fill="#31b39f" />
                    <path d="M7.2,4,4,1,.75,4" />
                  </svg>
                </span>
              </th>
              <th>URL de mon article</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(post, index) in posts" :key="index">
              <td v-if="new Date(post.date).toLocaleDateString() != 'Invalid Date'">
                {{ new Date(post.date).toLocaleDateString() }}
              </td>
              <td v-else>Aucune date</td>
              <td class="title">
                <h3>{{ post.title }}</h3>
              </td>
              <td>
                <span class="state" :class="`--${post.status}`">
                  {{ getPrettyState(post.status) }}
                </span>
              </td>
              <td class="url">
                <a
                  v-if="post.status === 'publish'"
                  :href="`${formattedDomainName}/articles/${post.name}`"
                  target="_blank"
                  >{{ formattedDomainName }}/articles/{{ post.name }}
                </a>
                <span v-else class="--waiting">En attente de publication...</span>
              </td>
              <td class="actions">
                <div class="div">
                  <button
                    class="button button--edit button--invalid button--x-small"
                    @click="$router.push({ name: 'Post', params: { id: post.id } })"
                  >
                    Modifier
                  </button>
                  <button
                    class="button button--remove button--x-small"
                    @click="deletepost(post)"
                  >
                    <svg
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z"
                        fill-rule="nonzero"
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <nav v-if="nbrPage > 1" class="Pagination">
          <button
            v-for="(row, rowindex) in nbrPage"
            :key="rowindex"
            :class="{ '--active': rowindex + 1 == page }"
            @click="refreshposts(rowindex + 1)"
          >
            <span v-if="rowindex + 1 == page">
              {{ rowindex + 1 }}
            </span>
            <span v-if="rowindex + 1 != page">{{ rowindex + 1 }}</span>
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
//import moment from 'moment';
import { mapGetters } from 'vuex';
import CircularLoader from '@/components/common/CircularLoader';

export default {
  components: {
    CircularLoader,
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      totalItems: 0,
      posts: [],
      nbrPage: 0,
      orders: {
        attribute: 'status',
        order: 'asc',
      },
    };
  },
  async created() {
    this.refreshposts(1);
  },

  computed: {
    ...mapGetters({
      getDomainName: 'site/getDomainName',
      getSiteId: 'site/getId',
    }),
    formattedDomainName() {
      return 'https://' + this.getDomainName;
    },
  },

  methods: {
    deletepost(post) {
      if (confirm('Êtes vous sûr de vouloir de supprimer cet article ?')) {
        api.delete(`/posts/${post.id}`);
        setTimeout(() => {
          this.refreshposts(this.page);
        }, 1000);
      }
    },
    changeOrder(attribute) {
      if (this.orders.attribute == attribute) {
        if (this.orders.order == 'asc') this.orders.order = 'desc';
        else if (this.orders.order == 'desc') this.orders.order = 'asc';
      } else {
        this.orders.attribute = attribute;
        this.orders.order = 'asc';
      }
      this.refreshposts(this.page);
    },
    async refreshposts(page) {
      this.isLoading = true;

      let params = {
        site: this.getSiteId,
        page: page,
      };
      params['order[' + this.orders.attribute + ']'] = this.orders.order;
      const { data: posts } = await api.get('/posts', {
        params: params,
      });
      this.totalItems = posts['hydra:totalItems'];
      this.page = page;
      /*
      this.posts = posts['hydra:member'].filter(post => {
          return 1;
      });*/
      this.posts = posts['hydra:member'];
      if (page == 1) {
        this.nbrPage = Math.ceil(this.totalItems / this.posts.length);
      }

      this.isLoading = false;
    },
    getPrettyState(state) {
      let prettyState;
      switch (state) {
        case 'publish':
          prettyState = 'Publié';
          break;
        case 'draft':
          prettyState = 'Brouillon';
          break;
        default:
          prettyState = state;
      }
      return prettyState;
    },
  },
};
</script>
