<template>
  <div class="step">
    <h1>
      Êtes-vous client Kerialis ?
    </h1>
    <GroupRadio v-model="alreadyClient">
      <GroupRadioInput
        id="alreadyClient_yes"
        name="alreadyClient"
        :value="true"
        :checked="alreadyClient === 'Oui'"
        modifier="radio-input__label--text"
      >
        <span class="radio-input-text radio-input-text--small">
          Oui
        </span>
      </GroupRadioInput>
      <GroupRadioInput
        id="alreadyClient_no"
        name="alreadyClient"
        :value="false"
        :checked="alreadyClient === 'Non'"
        modifier="radio-input__label--text"
      >
        <span class="radio-input-text radio-input-text--small">
          Non
        </span>
      </GroupRadioInput>
    </GroupRadio>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { api } from '@/lib/api';
import { GroupRadio, GroupRadioInput } from '@/components/common/form';

export default {
  components: { GroupRadioInput, GroupRadio },

  data() {
    return {
      alreadyClient: '',
    };
  },

  computed: {
    ...mapGetters({
      getAlreadyClient: 'user/getAlreadyClient',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    alreadyClient(value) {
      if (!this.$v.alreadyClient.$invalid) {
        if (value) this.updateAlreadyClient('Oui');
        else this.updateAlreadyClient('Non');
      }
    },
  },

  created() {
    this.alreadyClient = this.getAlreadyClient;

    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepAlreadyClient' });
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  methods: {
    ...mapActions({
      updateAlreadyClient: 'user/updateAlreadyClient',
    }),
  },

  validations: {
    alreadyClient: {
      required,
    },
  },
};
</script>
