<template>
  <div class="settings-block">
    <p class="text text--instruction">
      LOGO DE VOTRE CABINET
    </p>

    <p class="text text--hint">
      <strong>
        Préférez un format transparent en .png, ou à défaut un format .jpg en fond
        blanc,<br />de 200 pixels de largeur au minimum, et 2mo au maximum
      </strong>
      .<br /><br />
      Nous vous conseillons de redimensionner votre image.<br />
      Suite au téléchargement de votre logo, vous aurez la possibilité d'agrandir,
      <br />de réduire et de déplacer votre image dans une zone dédiée.
    </p>

    <div class="settings-block__settings">
      <ImageInput
        upload-label="Importer votre logo"
        delete-label="Supprimer le logo"
        :image-url="getOfficeLogo"
        @upload="updateOfficeLogo($event)"
        @remove="updateOfficeLogo('')"
      />
    </div>
  </div>
</template>

<script>
import { ImageInput } from '@/components/common/form';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { ImageInput },

  computed: {
    ...mapGetters({
      getOfficeLogo: 'office/getLogo',
    }),
  },

  methods: {
    ...mapActions({
      updateOfficeLogo: 'office/updateLogo',
    }),
  },
};
</script>
