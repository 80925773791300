<template>
  <div class="step step--large step--spaced domain-name">
    <h1 class="title--1">
      On y est presque, il ne reste qu’à paramétrer votre nom de domaine.
    </h1>

    <div class="domain-name__body">
      <p class="text text--instruction">
        AVEZ-VOUS DEJA UN NOM DE DOMAINE ?
      </p>

      <GroupRadio v-model="domainNameOwner">
        <GroupRadioInput
          :value="true"
          :checked="domainNameOwner === true"
          modifier="radio-input__label--text"
        >
          <span class="radio-input-text radio-input-text--small">
            Oui
          </span>
        </GroupRadioInput>
        <GroupRadioInput
          :value="false"
          :checked="domainNameOwner === false"
          modifier="radio-input__label--text"
        >
          <span class="radio-input-text radio-input-text--small">
            Non
          </span>
        </GroupRadioInput>
      </GroupRadio>
      <p class="text text--hint">
        Un nom de domaine est essentiel dans la création de votre site.<br />
        <strong>Ce nom sera votre identité sur internet et restera unique</strong>.
      </p>
    </div>

    <div v-if="domainNameOwner" class="domain-name__form">
      <p class="text text--instruction">RENSEIGNEZ VOTRE NOM DE DOMAINE</p>
      <div class="form-row">
        <input
          v-model="domainName"
          class="input"
          type="url"
          placeholder="pearsonspecterlitt.com"
        />
      </div>
    </div>

    <div v-if="domainNameOwner === false" class="domain-name__form">
      <p class="text text--instruction">
        PAS DE PROBLÈME, QUEL NOM DE DOMAINE SOUHAITERIEZ-VOUS ?
      </p>
      <p class="text text--hint">
        L’achat du nom de domaine est <strong>gratuit</strong> et
        <strong>compris dans nos services</strong>. <br />
        Ce nom de domaine vous appartiendra, même si vous n'utilisez plus la solution
        Solency.
      </p>
      <form @submit.prevent="checkAvaibility">
        <div class="form-row">
          <p class="text text--hint domain-name__protocole">https://www.</p>
          <input
            v-model="domainName"
            type="text"
            class="input"
            placeholder="pearsonspecterlitt.com"
          />
          <button class="button button--small domain-name__checkin" type="submit">
            Vérifier
          </button>
        </div>
      </form>

      <p class="text text--hint">
        <strong>
          Attention, les dénominations généralistes sont formellement interdites
        </strong>
        <br />
        <i>par exemple : www.avocat-divorce.fr.</i><br /><br />
        Votre nom de domaine
        <strong>
          doit comporter votre nom de famille<br />ou le nom du cabinet
        </strong>
        suivi ou non du terme "avocat"
        <strong>et une extension .fr ou .com</strong>.<br />
        <i>Par exemple : cabinetdupont.fr ou avocatsdupont.com.</i>
      </p>

      <p
        v-if="domainNameAvaibility !== null && !domainExtentionValide"
        class="check--not-available"
      >
        <img src="@/assets/svg/Bad-Check.svg" alt="" />
        <br />
        Nous acceptons uniquement les noms de domaine terminant par .fr, .com,
        .net.<br />
        Merci de choisir parmi cette liste..
      </p>

      <p v-else-if="errorMessage" class="check--not-available">
        <img src="@/assets/svg/Bad-Check.svg" alt="" />
        <br />
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { GroupRadio, GroupRadioInput } from '@/components/common/form';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { domainNameValidator } from '@/mixins/domainNameMixin';

export default {
  components: { GroupRadioInput, GroupRadio },

  data() {
    return {
      domainNameOwner: null,
      domainName: '',
      domainExtentionValide: false,
      domainNameAvaibility: null,
      errorMessage: '',
    };
  },

  computed: {
    ...mapGetters({
      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    domainName(value) {
      if (!this.$v.domainName.$invalid) {
        this.updateDomainName(value);
        this.updateExistingDomain(this.domainNameOwner);
      }
    },
  },

  created() {
    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepDomainName' });
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  methods: {
    ...mapActions({
      updateDomainName: 'site/updateDomainName',
      updateExistingDomain: 'site/updateExistingDomain',
    }),
    async checkAvaibility() {
      this.domainNameAvaibility = false;
      this.errorMessage = '';

      const domainExtention = this.domainName.split('.')[1];
      this.domainExtentionValide =
        domainExtention === 'fr' ||
        domainExtention === 'com' ||
        domainExtention === 'net';

      if (!this.domainExtentionValide && domainExtention) return;
      this.domainExtentionValide = true;

      try {
        const response = await api.get(`/domains?name=${this.domainName}`);
        if (response.data.successMessage) {
          this.domainNameAvaibility = true;
          this.errorMessage = '';
        } else {
          this.errorMessage = response.data.errorMessage;
          this.domainNameAvaibility = false;
        }
      } catch {
        this.errorMessage = 'Une erreur est survenue';
        this.domainNameAvaibility = false;
      }
    },
  },

  validations() {
    if (!this.domainNameOwner) {
      return {
        domainNameAvaibility: {
          required,
          checked: value => value === true,
        },
        domainName: {
          required,
        },
      };
    } else {
      return {
        domainName: {
          required,
          domainNameValidator,
        },
      };
    }
  },
};
</script>
