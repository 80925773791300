const toast = (message, time = Date.now()) => ({
  message,
  time,
});

const initialState = () => ({
  toasts: [],
});

const getters = {
  all: ({ toasts }) => toasts,
};

const mutations = {
  add(state, toast) {
    state.toasts.push(toast);
  },

  remove(state, time) {
    state.toasts = state.toasts.filter(toast => toast.time !== time);
  },
};

const actions = {
  notify({ commit }, message) {
    const t = toast(message);

    commit('add', t);

    setTimeout(() => {
      commit('remove', t.time);
    }, 10000);
  },

  'notify:save'({ dispatch }) {
    dispatch('notify', 'Vos modifications ont bien été sauvegardées');
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
