import Vue from 'vue';
import App from './App.vue';
import './assets/style/main.scss';
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(Vuelidate);
/*
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_CONFIG_ID,
  defer: false,
  compatibility: false,
  nonce: '2726c7f26c',
  trackOnNextTick: false,
});
*/
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
