<template>
  <div class="step step--wide step--big-spaced">
    <div class="domaines-settings__block">
      <h2 class="title--1 title--little-spaced">
        Quelles sont vos spécialités ?
      </h2>

      <p class="text text--hint">
        Vous pouvez sélectionner
        <strong>jusqu’à 2 mentions de spécialisation</strong><br />qui résultent de
        l’obtention d’un certificat de spécialisation.
      </p>

      <p class="text text--hint">
        <strong>Nous vous conseillons de bien détailler vos spécialités.</strong>
        <br />
        Vous pouvez utiliser plusieurs termes désignant la même chose.<br />Cela vous
        permettra à la fois de toucher plus de personnes, que votre audience vous
        comprenne mieux<br />mais également de bien vous situer en termes de
        résultats de recherche.
      </p>

      <div v-if="isLoading" class="simple__loader">
        <CircularLoader />
      </div>
      <div v-else class="domaines-settings__specialities">
        <div
          v-for="speciality in options"
          :key="speciality.id"
          class="speciality"
          :class="{ 'is--disabled': specialities.length >= 2 }"
        >
          <input
            :id="`speciality_${speciality.id}`"
            v-model="specialities"
            type="checkbox"
            class="speciality__checkbox"
            :value="speciality['@id']"
            @change="checkSpeciality"
          />
          <label :for="`speciality_${speciality.id}`">
            <span>{{ speciality.name }}</span>
            <img src="@/assets/svg/Check.svg" class="speciality__checkmark" alt="" />
          </label>
        </div>
      </div>

      <div v-if="specialities.length > 0" class="specialities__descriptions">
        <h2 class="title--3">
          <strong>Décrivez en quelques mots</strong><br />
          vos spécialités
        </h2>
        <div class="descriptions">
          <div
            v-for="(speciality_id, key) in specialities"
            :key="key"
            class="speciality__description"
          >
            <label :for="`specialities__descripton_${key}`">
              {{ getSpecialtyName(speciality_id) }}
            </label>
            <textarea
              :id="`specialities__descripton_${key}`"
              class="input input--textarea description__input"
              rows="20"
              cols="20"
              :value="customTexts[speciality_id]"
              placeholder="Informations supplémentaires"
              @change="checkCustomText($event, speciality_id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';
import CircularLoader from '@/components/common/CircularLoader';

export default {
  components: { CircularLoader },

  data() {
    return {
      isLoading: true,
      options: [],
      specialities: [],
      customTexts: [],
      interventions: [],
    };
  },

  computed: {
    ...mapGetters({
      getOfficeSpecialities: 'office/getSpecialities',
      getOfficeInterventions: 'office/getInterventions',
      getOfficeCustomTexts: 'office/getCustomTexts',
      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    specialities(specialities) {
      if (!this.$v.specialities.$invalid) {
        this.updateOfficeSpecialities(specialities);
      }
    },
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  async created() {
    const { data } = await api.get('/specialities');
    this.options = data['hydra:member'];
    this.specialities = this.getOfficeSpecialities;
    this.interventions = this.getOfficeInterventions;
    this.customTexts = this.getOfficeCustomTexts;

    this.isLoading = false;
    const idAuth = this.getId;
    await api.put('/users/' + idAuth, { LastStep: 'StepDomains' });
  },

  methods: {
    ...mapActions({
      updateOfficeSpecialities: 'office/updateSpecialities',
      updateOfficeInterventions: 'office/updateInterventions',
      updateOfficeCustomTexts: 'office/updateCustomTexts',
    }),
    getSpecialtyName(id) {
      let i = 0;
      while (i < this.options.length) {
        if (this.options[i]['@id'] == id) {
          return this.options[i].name;
        }
        i++;
      }
      return '';
    },
    suppression(id) {
      this.interventions.splice(id, 1);
      this.updateInterventions();
    },

    addIntervention() {
      this.interventions.push('');
      this.updateInterventions();
    },

    checkCustomText(e, speciality_id) {
      this.customTexts[speciality_id] = e.currentTarget.value;
      this.updateOfficeCustomTexts(this.customTexts);
    },
    checkIntervention(e, key) {
      e.currentTarget.value
        ? e.currentTarget.classList.remove('--empty')
        : e.currentTarget.classList.add('--empty');
      this.interventions[key] = e.currentTarget.value;
      this.interventions = [...this.interventions];
      this.updateInterventions();
    },

    updateInterventions() {
      this.updateOfficeInterventions(
        this.interventions.filter(intervention => intervention !== '')
      );
    },

    checkSpeciality(e) {
      let max_specialities = 2;
      if (this.specialities.length >= max_specialities) {
        e.preventDefault();
        e.currentTarget.checked = false;
        this.specialities = this.specialities.slice(0, max_specialities);
        return false;
      }
    },
  },

  validations: {
    specialities: {
      maxLength: maxLength(2),
    },
  },
};
</script>
