import { api } from '@/lib/api';

const initialState = () => ({
  id: '',
  appointments: [],
  availabilities: [],
  coworker: '',
  inPersonAppointments: true,
  jsonAvailabilities: [],
  phoneCallsAppointments: true,
  timeSlotDuration: 1,
  unavailabilities: [],
  videoCallsAppointments: true,
});

const getters = {
  state: state => state,
};

const mutations = {
  setId(state, id) {
    state.id = id;
  },

  setAppointments(state, appointments) {
    state.appointments = appointments;
  },

  setAvailabilities(state, availabilities) {
    state.availabilities = availabilities;
  },

  setCoworker(state, coworker) {
    state.coworker = coworker;
  },

  setInPersonAppointments(state, inPersonAppointments) {
    state.inPersonAppointments = inPersonAppointments;
  },

  setJsonAvailabilities(state, jsonAvailabilities) {
    state.jsonAvailabilities = jsonAvailabilities;
  },

  setPhoneCallsAppointments(state, phoneCallsAppointments) {
    state.phoneCallsAppointments = phoneCallsAppointments;
  },

  setTimeSlotDuration(state, timeSlotDuration) {
    state.timeSlotDuration = timeSlotDuration;
  },

  setUnavailabilities(state, unavailabilities) {
    state.unavailabilities = unavailabilities;
  },

  setVideoCallsAppointments(state, videoCallsAppointments) {
    state.videoCallsAppointments = videoCallsAppointments;
  },
};

const actions = {
  updateState({ commit }, data) {
    commit('setId', data['@id']);
    commit('setCoworker', data.coworker);
    commit('setInPersonAppointments', data.inPersonAppointments);
    commit('setAppointments', data.appointments);
    commit('setAvailabilities', data.availabilities);
    commit('setJsonAvailabilities', data.jsonAvailabilities);
    commit('setPhoneCallsAppointments', data.phoneCallsAppointments);
    commit('setVideoCallsAppointments', data.videoCallsAppointments);
    commit('setTimeSlotDuration', data.timeSlotDuration);
    commit('setUnavailabilities', data.unavailabilities);
  },

  async fetch({ dispatch }, id) {
    const { data } = await api.get(id);

    await dispatch('updateState', data);
  },

  async updateJsonAvailabilities({ dispatch, state }, jsonAvailabilities) {
    const { data } = await api.put(state.id, {
      jsonAvailabilities,
    });

    await dispatch('updateState', data);
    await dispatch('toastr/notify:save', null, { root: true });
  },

  async updateTimeSlotDuration({ state, dispatch }, timeSlotDuration) {
    const { data } = await api.put(state.id, {
      timeSlotDuration,
    });

    await dispatch('updateState', data);
    await dispatch('toastr/notify:save', null, { root: true });
  },

  async putSchedule({ state }) {
    await api.put(state.id, state);
  },

  async updateInPersonAppointments({ dispatch, state }, inPersonAppointments) {
    const { data } = await api.put(state.id, {
      inPersonAppointments,
    });

    dispatch('updateState', data);
    await dispatch('toastr/notify:save', null, { root: true });
  },

  async updatePhoneCallsAppointments({ dispatch, state }, phoneCallsAppointments) {
    const { data } = await api.put(state.id, {
      phoneCallsAppointments,
    });

    dispatch('updateState', data);
    await dispatch('toastr/notify:save', null, { root: true });
  },

  async updateVideoCallsAppointments({ dispatch, state }, videoCallsAppointments) {
    const { data } = await api.put(state.id, {
      videoCallsAppointments,
    });

    dispatch('updateState', data);
    await dispatch('toastr/notify:save', null, { root: true });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
