<template>
  <div class="step-template-choice step step--full step--spaced">
    <h1 class="title title--1">
      Parfait. Il est temps de choisir le <br />design de votre site
    </h1>
    <p class="text text--instruction">QUEL TEMPLATE SOUHAITEZ-VOUS ?</p>

    <div v-if="isLoading" class="slider__loader">
      <CircularLoader />
    </div>

    <swiper
      v-else
      ref="swiper"
      class="step-template-choice__templates-slider templates-slider"
      :options="swiperOptions"
    >
      <div
        slot="button-prev"
        class="templates-slider__navigation templates-slider__navigation--prev"
      >
        <img
          src="@/assets/svg/slide-arrow.svg"
          class="templates-slider__navigation__icon mirror"
          alt=""
          @click="$refs.swiper.$swiper.slidePrev()"
        />
      </div>

      <swiper-slide
        v-for="template in templates"
        :key="template.id"
        class="templates-slider__slide"
      >
        <div
          :class="[
            'templates-slider__slide__image',
            { selected: selected(template['@id']) },
          ]"
        >
          <img
            v-if="selected(template['@id'])"
            class="check-icon check-icon--large"
            src="@/assets/svg/Check.svg"
          />
          <img
            class="templates-slider__slide__image__img"
            :src="templateImageUrl(template.name)"
            alt=""
          />
          <div
            v-if="!selected(template['@id'])"
            class="templates-slider__slide__image__cta"
          >
            <button
              class="button button--negative button--small"
              @click="updateSiteVersionTemplate(template['@id'])"
            >
              Choisir ce design
            </button>
          </div>
        </div>
        <a
          :href="previewUrl(template.name)"
          class="link templates-slider__slide__image__preview link--large"
          target="_blank"
          >Voir un aperçu de {{ template.name }}</a
        >
      </swiper-slide>

      <div
        slot="button-next"
        class="templates-slider__navigation templates-slider__navigation--next"
      >
        <img
          src="@/assets/svg/slide-arrow.svg"
          class="templates-slider__navigation__icon"
          alt=""
          @click="$refs.swiper.$swiper.slideNext()"
        />
      </div>
    </swiper>

    <div class="step-template-choice__templates templates">
      <div v-for="template in templates" :key="template.id" class="template">
        <div :class="['template__image', { selected: selected(template['@id']) }]">
          <img
            v-if="selected(template['@id'])"
            class="check-icon check-icon--large"
            src="@/assets/svg/Check.svg"
          />
          <img
            class="template__image__img"
            alt=""
            :src="templateImageUrl(template.name)"
          />
          <div v-if="!selected(template['@id'])" class="template__image__cta">
            <button
              class="button button--negative button--small"
              @click="$emit('input', template['@id'])"
            >
              Choisir ce design
            </button>
          </div>
        </div>
        <a
          :href="previewUrl(template.name)"
          class="link template__preview"
          target="_blank"
        >
          Voir un aperçu {{ template.name }}
        </a>
      </div>
    </div>
    <div class="icon-space-text">
      <img src="@/assets/svg/no-pannic.svg" alt="" />
    </div>
    <div class="step__bottom-text">
      <span class="text text--instruction-large">
        Vous avez du mal à vous décider ? Pas de panique, vous pourrez </span
      ><span class="text text--instruction-large text--highlight">
        changer votre design à tout moment.</span
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { api } from '@/lib/api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import CircularLoader from '@/components/common/CircularLoader';

export default {
  components: {
    Swiper,
    SwiperSlide,
    CircularLoader,
  },

  data() {
    return {
      isLoading: true,
      templates: [],
    };
  },

  mounted() {
    this.$emit('validate', false);
  },

  async created() {
    const { data } = await api.get('/templates');
    this.isLoading = false;
    this.templates = data['hydra:member'];

    setTimeout(() => {
      this.$refs['swiper'].$swiper.slideTo(
        this.templates
          .map(template => '/templates/' + template.id)
          .indexOf(this.getSiteVersionTemplate),
        0
      );
    }, 30);

    if (!this.getSiteVersionTemplate) {
      this.updateSiteVersionTemplate(data['hydra:member'][0]['@id']);
    }

    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepTemplateChoice' });
  },

  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters({
      getSiteVersionTemplate: 'siteVersion/getTemplate',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),

    swiperOptions() {
      const spaceBetweenSlides = window.innerWidth / 2 - 184;

      return {
        slidesPerView: 3,
        spaceBetween: spaceBetweenSlides,
        centeredSlides: true,
      };
    },
  },

  methods: {
    ...mapActions({
      updateSiteVersionTemplate: 'siteVersion/updateTemplate',
    }),

    previewUrl(templateName) {
      return `${process.env.VUE_APP_PREVIEW_URL}/${templateName}/dist/index.html`;
    },

    templateImageUrl(templateName) {
      return require(`@/assets/images/${templateName}.jpg`);
    },

    selected(templateId) {
      return templateId === this.getSiteVersionTemplate;
    },
  },
};
</script>
