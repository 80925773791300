<template>
  <div class="step step--large step--spaced">
    <h1 class="title title--1">
      Très bon choix. Maintenant, nous allons personnaliser un peu votre site.
    </h1>

    <p class="text text--instruction">SI VOUS AVEZ UN LOGO, IMPORTEZ-LE ICI</p>
    <p class="text text--hint">
      <strong>
        Préférez un format transparent en .png, ou à défaut un format .jpg en fond
        blanc,<br />de 200 pixels de largeur au minimum, et 2mo au maximum
      </strong>
      .<br /><br />
      Nous vous conseillons de redimensionner votre image.<br />
      Suite au téléchargement de votre logo, vous aurez la possibilité d'agrandir,
      <br />de réduire et de déplacer votre image dans une zone dédiée.
    </p>

    <ImageInput
      upload-label="Importer votre logo"
      delete-label="Supprimer le logo"
      :image-url="getOfficeLogo"
      @upload="updateOfficeLogo($event)"
      @remove="updateOfficeLogo('')"
    />

    <p class="text text--instruction">CHOISISSEZ UN DUO DE COULEUR</p>
    <ColorInput
      :selected-color="getSiteVersionColorConfig"
      @input="updateSiteVersionColorConfig($event)"
    />

    <div class="typo-input">
      <p class="text text--instruction text--with-margin">
        CHOISISSEZ UN COUPLE DE TYPOGRAPHIES
      </p>

      <GroupRadio v-model="fontConfig">
        <GroupRadioInput
          v-for="(font, index) in fonts"
          :id="`colorRadio_${index}`"
          :key="index"
          class="test"
          modifier="radio-input__label--white"
          :value="font"
          :checked="isEqual(font, getSiteVersionFontConfig)"
        >
          <RadioFont :primary="font.primary" :secondary="font.secondary">
            <img
              v-if="isEqual(font, getSiteVersionFontConfig)"
              src="@/assets/svg/Check.svg"
              class="check-icon"
              alt=""
            />
          </RadioFont>
        </GroupRadioInput>
      </GroupRadio>
    </div>
    <div class="icon-space-text">
      <img src="@/assets/svg/no-pannic.svg" alt="" />
    </div>
    <div class="step__bottom-text">
      <span class="text text--instruction-large">
        Vous avez du mal à vous décider ? Pas de panique, vous pourrez </span
      ><span class="text text--instruction-large text--highlight">
        changer votre design à tout moment.</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GroupRadio, GroupRadioInput } from '@/components/common/form';
import { RadioFont } from '@/components';
import { isEqual } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { ImageInput, ColorInput } from '@/components/common/form';
import { api } from '@/lib/api';

export default {
  components: { GroupRadioInput, GroupRadio, RadioFont, ImageInput, ColorInput },
  data() {
    return {
      colorConfig: null,
      fontConfig: null,

      fonts: [
        {
          primary: 'Manrope',
          secondary: 'Manrope',
        },
        {
          primary: 'Titillium Web',
          secondary: 'Titillium Web',
        },
        {
          primary: 'Merriweather',
          secondary: 'Lato',
        },
        {
          primary: 'Red Rose',
          secondary: 'Roboto',
        },
      ],

      image: {
        size: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      getOfficeLogo: 'office/getLogo',
      getSiteVersionColorConfig: 'siteVersion/getColorConfig',
      getSiteVersionFontConfig: 'siteVersion/getFontConfig',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    colorConfig(colorConfig) {
      if (!this.$v.colorConfig.$invalid) {
        this.updateSiteVersionColorConfig(colorConfig);
      }
    },

    fontConfig(fontConfig) {
      if (!this.$v.fontConfig.$invalid) {
        this.updateSiteVersionFontConfig(fontConfig);
      }
    },
  },

  created() {
    this.colorConfig = this.getSiteVersionColorConfig;
    this.fontConfig = this.getSiteVersionFontConfig;

    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepCustomization' });
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  methods: {
    ...mapActions({
      updateOfficeLogo: 'office/updateLogo',
      updateSiteVersionColorConfig: 'siteVersion/updateColorConfig',
      updateSiteVersionFontConfig: 'siteVersion/updateFontConfig',
    }),

    isEqual,
  },

  validations: {
    colorConfig: {
      required,
    },
    fontConfig: {
      required,
    },
  },
};
</script>
