export default class HtmlDisplayer {
  interventions = [];
  customTexts = [];
  constructor() {}

  init() {
    console.log('Init HtmlDisplayer');
  }

  getSiteVersionTemplate080d02e0(name, option_id, type) {
    this.pos++;
    let text = this.customTexts[option_id];
    if (typeof text == 'undefined') {
      text = '';
    }
    if (typeof type == 'undefined') {
      type = '';
    }
    let specialityList =
      '<div class="domaines_interventions_card">\n' +
      '<div class="domaines_interventions_card_content">\n' +
      '<span class="domaines_interventions_card_tag">' +
      type +
      '</span>' +
      '<div class="card_ttl">\n' +
      '<h5 id="Speciality1-title">' +
      name +
      '</h5>\n' +
      '</div>\n' +
      '          <div class="card_content">\n' +
      '            <div class="custom-text" data-id="' +
      option_id +
      '" id="Speciality1-text">' +
      text +
      '</div>\n' +
      '          </div>' +
      '\n' +
      '<div class="card_link">\n' +
      '<a href="#contact" class="btn_secondary">Contactez-nous</a>\n' +
      '</div>\n' +
      '</div>\n' +
      '</div>';
    return specialityList;
  }

  getSiteVersionTemplate3c44a498(name, option_id, type) {
    this.pos++;
    let text = this.customTexts[option_id];
    if (typeof text == 'undefined') {
      text = '';
    }
    if (typeof type == 'undefined') {
      type = '';
    }
    let specialityList =
      '<div class="swiper-slide">\n' +
      '                  \n' +
      '                  <div class="domaine_card">\n' +
      '  <div class="domaine_card_content">\n' +
      '<span class="domaine_card_tag">' +
      type +
      '</span>' +
      '    <h5 id="Speciality1-title">' +
      name +
      '</h5>\n' +
      '<div data-id="' +
      option_id +
      '" id="Speciality1-text" class="custom-text">' +
      text +
      '</div>' +
      '    \n' +
      '  </div>\n' +
      '  \n' +
      '</div>\n' +
      '\n' +
      '                </div>';
    return specialityList;
  }

  getSiteVersionTemplatec79c1c8b(name, option_id, type) {
    this.pos++;
    let text = this.customTexts[option_id];
    if (typeof text == 'undefined') {
      text = '';
    }
    if (typeof type == 'undefined') {
      type = '';
    }
    let specialityList =
      '<li class="speciality_card">' +
      '<div class="inner">' +
      '<span class="speciality_card_tag">' +
      type +
      '</span>' +
      '<h5 id="Speciality1-title">' +
      name +
      '</h5>' +
      '<div class="custom-text" data-id="' +
      option_id +
      '" id="Speciality1-text">' +
      text +
      '</div>' +
      '</div>' +
      '</li>';

    return specialityList;
  }

  getSiteVersionTemplatef351a2f9(name, option_id, type) {
    this.pos++;
    let text = this.customTexts[option_id];
    if (typeof text == 'undefined') {
      text = '';
    }
    if (typeof type == 'undefined') {
      type = '';
    }
    let specialityList =
      '<li class="speciality_card">\n' +
      '  <div class="inner">\n' +
      '    <div class="inner_title">\n' +
      '<span class="speciality_card_tag">' +
      type +
      '</span>' +
      '      <h5 id="Speciality1-title">' +
      name +
      '</h5>\n';
    if (text != '') {
      specialityList +=
        '<div class="custom-text" data-id="' +
        option_id +
        '" id="Speciality1-text"' +
        ' class="arrow">\n' +
        '          <svg height="16px" width="13px" viewBox="0 0 16 13.4">\n' +
        '            <path class="arrow_path" d="M0,7.57H12.67l-4.6,4.6L9.3,13.4,16,6.7,9.3,0,8.07,1.23l4.6,4.6H0Z"></path>\n' +
        '          </svg>\n' +
        '          <span>' +
        text +
        '</span>\n' +
        '        </div>';
    }
    specialityList += '    </div>\n' + '    \n' + '  </div>\n' + '</li>';
    return specialityList;
  }

  getSiteVersionTemplate1e1d32a9(name, option_id, type) {
    this.pos++;
    let text = this.customTexts[option_id];
    if (typeof text == 'undefined') {
      text = '';
    }
    if (typeof type == 'undefined') {
      type = '';
    }
    let specialityList =
      '<li class="speciality_card">' +
      '<div class="inner">' +
      '<span class="speciality_card_tag">' +
      type +
      '</span>' +
      '<h5 id="Speciality1-title">' +
      name +
      '</h5>' +
      '<div class="custom-text" data-id="' +
      option_id +
      '" id="Speciality1-text">' +
      text +
      '</div>' +
      '</div>' +
      '</li>';
    return specialityList;
  }

  getSiteVersionTemplate994827f7(name, option_id, type) {
    this.pos++;
    let text = this.customTexts[option_id];
    if (typeof text == 'undefined') {
      text = '';
    }
    if (typeof type == 'undefined') {
      type = '';
    }
    let specialityList =
      '<div class="swiper-slide">' +
      '<div class="speciality_card">' +
      '<div class="inner">' +
      '<span class="count">' +
      '0' +
      this.pos +
      '</span>' +
      '<span class="speciality_card_tag">' +
      type +
      '</span>' +
      '<h5 id="Intervention1-title">' +
      name +
      '</h5>' +
      '<div id="Speciality1-text" class="custom-text" data-id="' +
      option_id +
      '">\n' +
      '<p>' +
      text +
      '</p>\n' +
      '</div>\n' +
      '<a href="#contact" class="btn"><span>Contactez-nous</span></a>' +
      '</div>' +
      '</div>' +
      '</div>';
    return specialityList;
  }

  getSiteVersionTemplate839f1ba7(name, option_id, type) {
    this.pos++;
    let text = this.customTexts[option_id];
    if (typeof text == 'undefined') {
      text = '';
    }
    if (typeof type == 'undefined') {
      type = '';
    }
    let specialityList =
      '<li class="speciality_card">\n' +
      '<div class="inner">\n' +
      '<div class="inner_title">\n' +
      '<span class="speciality_card_tag">' +
      type +
      '</span>\n' +
      '<h5 id="Intervention1-title">' +
      name +
      '</h5>\n' +
      '<div id="Speciality1-text" class="custom-text" data-id="' +
      option_id +
      '">\n' +
      '<p>' +
      text +
      '</p>\n' +
      '</div>\n' +
      '\n' +
      '</div>\n' +
      '</div>\n' +
      '</li>';
    return specialityList;
  }

  getSiteVersionTemplateOther(name, option_id, type) {
    this.pos++;
    let text = this.customTexts[option_id];
    if (typeof text == 'undefined') {
      text = '';
    }
    if (typeof type == 'undefined') {
      type = '';
    }
    let specialityList =
      '<li class="speciality_card">\n' +
      '<div class="inner">\n' +
      '<span class="count">\n' +
      '0' +
      this.pos +
      '.\n' +
      '</span>\n' +
      '<span class="speciality_card_tag">' +
      type +
      '</span>\n' +
      '<h5 id="Speciality1-title">' +
      name +
      '</h5>\n' +
      '\n' +
      '<div id="Speciality1-text" data-id="' +
      option_id +
      '" class="custom-text">\n' +
      '<p>' +
      text +
      '</p>\n' +
      '</div>\n' +
      '\n' +
      '<a href="#contact">Contactez-nous</a>\n' +
      '</div>\n' +
      '</li>';
    return specialityList;
  }

  updateSpecialityBloc(data) {
    this.interventions = data.interventions;
    this.customTexts = data.customTexts;
    this.specialities = data.specialities;
    this.options = data.options;
    this.getSiteVersionTemplate = data.getSiteVersionTemplate;

    this.pos = 0;
    let specialityList = '';
    // template 1
    //this.updateOfficeInterventions(this.interventions.filter(intervention => intervention !== ''));
    if (
      this.getSiteVersionTemplate ==
        '/templates/080d02e0-5b83-4b95-9002-cccb6c399b24' ||
      this.getSiteVersionTemplate ==
        '/templates/9aae8ebd-9be3-449b-a30b-354edc199d77'
    ) {
      this.interventions.forEach(intervention => {
        specialityList += this.getSiteVersionTemplate080d02e0(
          intervention,
          intervention,
          "Domaines d'intervention"
        );
      });
      this.specialities.forEach(speciality => {
        if (typeof speciality == 'string') {
          this.options.forEach(option => {
            if (option['@id'] == speciality) {
              specialityList += this.getSiteVersionTemplate080d02e0(
                option.name,
                option['@id'],
                'Spécialité'
              );
            }
          });
        }
      });
    } else if (
      this.getSiteVersionTemplate ==
        '/templates/3c44a498-36ca-46b8-bf82-7a43be128192' ||
      this.getSiteVersionTemplate ==
        '/templates/402da0e6-3339-4b77-8930-5b4010cc2011'
    ) {
      this.interventions.forEach(intervention => {
        specialityList += this.getSiteVersionTemplate3c44a498(
          intervention,
          intervention,
          "Domaines d'intervention"
        );
      });
      this.specialities.forEach(speciality => {
        if (typeof speciality == 'string') {
          this.options.forEach(option => {
            if (option['@id'] == speciality) {
              specialityList += this.getSiteVersionTemplate3c44a498(
                option.name,
                option['@id'],
                'Spécialité'
              );
            }
          });
        }
      });
    } else if (
      this.getSiteVersionTemplate ==
        '/templates/c79c1c8b-dce1-4a09-9c2a-f1f1bd5166f9' ||
      this.getSiteVersionTemplate ==
        '/templates/754c38d0-b303-462a-9e13-d927ad858683'
    ) {
      specialityList += '<ul>';
      this.interventions.forEach(intervention => {
        specialityList += this.getSiteVersionTemplatec79c1c8b(
          intervention,
          intervention,
          "Domaines d'intervention"
        );
      });
      this.specialities.forEach(speciality => {
        if (typeof speciality == 'string') {
          this.options.forEach(option => {
            if (option['@id'] == speciality) {
              specialityList += this.getSiteVersionTemplatec79c1c8b(
                option.name,
                option['@id'],
                'Spécialité'
              );
            }
          });
        }
      });
      specialityList += '</ul>';
    } else if (
      this.getSiteVersionTemplate ==
        '/templates/f351a2f9-67ee-4f12-b5bf-868829ae1aa0' ||
      this.getSiteVersionTemplate ==
        '/templates/6971e3a7-3c33-4ed0-9ee6-e81ca3b46617'
    ) {
      specialityList += '<ul>';
      this.interventions.forEach(intervention => {
        specialityList += this.getSiteVersionTemplatef351a2f9(
          intervention,
          intervention,
          "Domaines d'intervention"
        );
      });
      this.specialities.forEach(speciality => {
        if (typeof speciality == 'string') {
          this.options.forEach(option => {
            if (option['@id'] == speciality) {
              specialityList += this.getSiteVersionTemplatef351a2f9(
                option.name,
                option['@id'],
                'Spécialité'
              );
            }
          });
        }
      });
      specialityList += '</ul>';
    } else if (
      this.getSiteVersionTemplate ==
        '/templates/1e1d32a9-f325-4aaa-83f8-ba757dafb524' ||
      this.getSiteVersionTemplate ==
        '/templates/af4988eb-1585-4797-8ec0-f36b2117828b'
    ) {
      specialityList += '<ul>';
      this.interventions.forEach(intervention => {
        specialityList += this.getSiteVersionTemplate1e1d32a9(
          intervention,
          intervention,
          "Domaines d'intervention"
        );
      });
      this.specialities.forEach(speciality => {
        if (typeof speciality == 'string') {
          this.options.forEach(option => {
            if (option['@id'] == speciality) {
              specialityList += this.getSiteVersionTemplate1e1d32a9(
                option.name,
                option['@id'],
                'Spécialité'
              );
            }
          });
        }
      });
      specialityList += '</ul>';
    } else if (
      this.getSiteVersionTemplate ==
        '/templates/994827f7-065c-45ac-8565-852996a0d24c' ||
      this.getSiteVersionTemplate ==
        '/templates/11e531f2-adb2-46c9-89a2-40d1f91e178d'
    ) {
      this.interventions.forEach(intervention => {
        specialityList += this.getSiteVersionTemplate994827f7(
          intervention,
          intervention,
          "Domaines d'intervention"
        );
      });
      this.specialities.forEach(speciality => {
        if (typeof speciality == 'string') {
          this.options.forEach(option => {
            if (option['@id'] == speciality) {
              specialityList += this.getSiteVersionTemplate994827f7(
                option.name,
                option['@id'],
                'Spécialité'
              );
            }
          });
        }
      });
    } else if (
      this.getSiteVersionTemplate ==
        '/templates/839f1ba7-7bef-4ea0-9757-8950d6ab6cf8' ||
      this.getSiteVersionTemplate ==
        '/templates/5d2a1a44-ea5d-4cba-8586-93a46414ccd9'
    ) {
      specialityList += '<ul>';
      this.interventions.forEach(intervention => {
        specialityList += this.getSiteVersionTemplate839f1ba7(
          intervention,
          intervention,
          "Domaine d'intervention"
        );
      });
      this.specialities.forEach(speciality => {
        if (typeof speciality == 'string') {
          this.options.forEach(option => {
            if (option['@id'] == speciality) {
              specialityList += this.getSiteVersionTemplate839f1ba7(
                option.name,
                option['@id'],
                'Spécialité'
              );
            }
          });
        }
      });
      specialityList += '</ul>';
    } else {
      specialityList += '<ul>';
      this.interventions.forEach(intervention => {
        specialityList += this.getSiteVersionTemplateOther(
          intervention,
          intervention,
          "Domaine d'intervention"
        );
      });
      this.specialities.forEach(speciality => {
        if (typeof speciality == 'string') {
          this.options.forEach(option => {
            if (option['@id'] == speciality) {
              specialityList += this.getSiteVersionTemplateOther(
                option.name,
                option['@id'],
                'Spécialité'
              );
            }
          });
        }
      });
      specialityList += '</ul>';
    }
    return specialityList;
    //this.$emit('confirm', this.specialities, specialityList);
  }
}
