<template>
  <div class="step">
    <h1>
      Avez-vous des salariés ?
    </h1>
    <GroupRadio v-model="coworkerNumber">
      <GroupRadioInput
        id="coworkerNumber_yes"
        name="coworkerNumber"
        :value="true"
        :checked="coworkerNumber === 'Oui'"
        modifier="radio-input__label--text"
      >
        <span class="radio-input-text radio-input-text--small">
          Oui
        </span>
      </GroupRadioInput>
      <GroupRadioInput
        id="coworkerNumber_no"
        name="coworkerNumber"
        :value="false"
        :checked="coworkerNumber === 'Non'"
        modifier="radio-input__label--text"
      >
        <span class="radio-input-text radio-input-text--small">
          Non
        </span>
      </GroupRadioInput>
    </GroupRadio>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { api } from '@/lib/api';
import { GroupRadio, GroupRadioInput } from '@/components/common/form';

export default {
  components: { GroupRadioInput, GroupRadio },

  data() {
    return {
      coworkerNumber: null,
    };
  },

  computed: {
    ...mapGetters({
      getCoworkerNumber: 'office/getCoworkerNumber',

      getUserState: 'user/getState',
      getId: 'auth/getId',
    }),
  },

  watch: {
    '$v.$invalid'(invalid) {
      this.$emit('validate', invalid);
    },

    coworkerNumber(value) {
      if (!this.$v.coworkerNumber.$invalid) {
        if (value) this.updateCoworkerNumber('Oui');
        else this.updateCoworkerNumber('Non');
      }
    },
  },

  created() {
    this.coworkerNumber = this.getCoworkerNumber;

    const idAuth = this.getId;
    api.put('/users/' + idAuth, { LastStep: 'StepCoworkerNumber' });
  },

  mounted() {
    this.$v.$touch();
    this.$emit('validate', this.$v.$invalid);
  },

  methods: {
    ...mapActions({
      updateCoworkerNumber: 'office/updateCoworkerNumber',
    }),
  },

  validations: {
    coworkerNumber: {
      required,
    },
  },
};
</script>
