<template>
  <div class="global-settings">
    <h1 class="title--1 title--unspaced">
      Informations concernant les avocats
    </h1>

    <div class="global-settings__block">
      <Coworkers />
    </div>
  </div>
</template>

<script>
import {
  AddressAndName,
  Contact,
  Logo,
  SocialNetworks,
  Coworkers,
  DescriptionAndFees,
} from '../SettingsBlocks';

export default {
  components: {
    Coworkers
  },
};
</script>
