<template>
  <div class="welcome">
    <div class="wrapper">
      <div class="welcome__header">
        <a href="https://www.solency.com/">
          <img src="@/assets/svg/logo-solency.svg" />
        </a>
        <div>
          <h1 class="title">
            <span class="title--bold">L'univers Solency à<br />portée de main</span>
          </h1>
          <p>
            De la création de votre site à la découverte de nos services, Solency
            répond à vos besoins.<br />
            Par quoi souhaitez-vous commencer ?
          </p>
        </div>
      </div>
      <div class="welcome__cards">
        <template v-for="(item, index) in items">
          <div
            v-if="!item.isHidden"
            :key="index"
            :class="`welcome-card welcome-card--style${index}`"
          >
            <div class="welcome-card__content">
              <div v-if="item.image" class="card-image">
                <img :src="item.image" :alt="item.title" />
              </div>
              <h3
                :class="`welcome-card--style${index}--color`"
                v-html="item.title"
              />
              <router-link
                v-if="item.buttonRoute"
                :to="{ name: item.buttonRoute }"
                tag="button"
                :class="
                  `button button--small welcome-card--style${index}--button-color`
                "
              >
                <span>{{ item.buttonLabel }}</span>
              </router-link>
              <a
                v-else
                :href="item.buttonLink"
                :class="
                  `button button--small welcome-card--style${index}--button-color`
                "
              >
                <span>{{ item.buttonLabel }}</span>
              </a>
            </div>
          </div>
        </template>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { api } from '@/lib/api';
import { mapActions, mapGetters } from 'vuex';
import Footer from '@/components/Footer';

export default {
  components: { Footer },
  data() {
    return {
      items: [
        {
          title: 'Créer gratuitement<br/>votre site web<br/>en quelques minutes',
          buttonLabel: 'Je crée mon site',
          buttonRoute: 'StepZero',
          image: require('@/assets/images/poulpe_computer.png'),
        },
        {
          isHidden: this.getToken !== null,
          title:
            'Toujours pas<br/>de compte Solency ?<br/>Inscrivez-vous rapidement !',
          buttonLabel: 'Je crée mon compte',
          buttonLink: 'https://www.solency.com/?redirect_to=https%3A%2F%2Fmonespace.solency.com%2F',
          image: require('@/assets/images/poulpe_rocket.png'),
        },
        {
          title:
            'Vous hésitez encore ?<br/> Continuez votre visite<br/>sur le site Solency',
          buttonLabel: `J'accéde au site`,
          buttonLink: 'https://www.solency.com/',
          image: require('@/assets/images/poulpe_loupe.png'),
        },
      ],
      apiURL: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    ...mapGetters({
      getId: 'auth/getId',
      eLearningUrl: 'user/getELearningUrl',
    }),
    getToken() {
      const token = this.eLearningUrl.split('https://formations.solency.com')[1];
      return token;
    },
  },
  async created() {
    const userId = this.getId;

    const { data: user } = await api.get(`/users/${userId}`);

    this.updateUser(user);
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateUser',
    }),
  },
};
</script>
