import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import {
  auth,
  messages,
  office,
  site,
  settings,
  siteVersion,
  user,
  coworkers,
  schedule,
  toastr,
} from '@/store/modules';

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: sessionStorage,
      paths: [
        'user',
        'messages',
        'office',
        'site',
        'siteVersion',
        'coworker',
        'settings',
      ],
    }),
    createPersistedState({
      storage: localStorage,
      paths: ['auth'],
    }),
  ],
  modules: {
    office,
    site,
    settings,
    siteVersion,
    auth,
    messages,
    user,
    coworkers,
    schedule,
    toastr,
  },
});

export default store;
