var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-settings"},[_c('div',{staticClass:"site-settings__header"},[_c('div',{staticClass:"site-settings__header__tabs"},[_c('a',{class:[
          'site-settings__header__tabs__tab',
          {
            'site-settings__header__tabs__tab--active':
              _vm.currentTab === 'GlobalSettings',
          } ],on:{"click":function($event){return _vm.goToStep('GlobalSettings')}}},[_vm._v(" Étape 1 ")]),_c('a',{class:[
          'site-settings__header__tabs__tab',
          {
            'site-settings__header__tabs__tab--active':
              _vm.currentTab === 'SpecialityTab',
          } ],on:{"click":function($event){return _vm.goToStep('SpecialityTab')}}},[_vm._v(" Étape 2 ")]),_c('a',{class:[
          'site-settings__header__tabs__tab',
          {
            'site-settings__header__tabs__tab--active': _vm.currentTab === 'LawFirm',
          } ],on:{"click":function($event){return _vm.goToStep('LawFirm')}}},[_vm._v(" Étape 3 ")]),_c('a',{class:[
          'site-settings__header__tabs__tab',
          {
            'site-settings__header__tabs__tab--active': _vm.currentTab === 'Coworkers',
          } ],on:{"click":function($event){return _vm.goToStep('Coworkers')}}},[_vm._v(" Étape 4 ")]),_c('a',{class:[
          'site-settings__header__tabs__tab',
          {
            'site-settings__header__tabs__tab--active':
              _vm.currentTab === 'DesignSettings',
          } ],on:{"click":function($event){return _vm.goToStep('DesignSettings')}}},[_vm._v(" Étape 5 ")]),_c('a',{class:[
          'site-settings__header__tabs__tab',
          { 'site-settings__header__tabs__tab--active': _vm.currentTab === 'Legals' } ],on:{"click":function($event){return _vm.goToStep('Legals')}}},[_vm._v(" Étape 6 ")]),_c('a',{class:[
          'site-settings__header__tabs__tab',
          {
            'site-settings__header__tabs__tab--active': _vm.currentTab === 'LiveEdit',
          } ],on:{"click":function($event){return _vm.goToStep('LiveEdit')}}},[_c('img',{staticClass:"site-settings__header__tabs__tab__icon",attrs:{"src":require("@/assets/svg/live-edit.svg"),"alt":""}}),_vm._v(" Prévisualisation ")]),(_vm.currentTab !== 'LiveEdit')?_c('a',{staticClass:"site-settings__header__tabs__tab site-settings__header__tabs__tab--active site-settings__visibility-icon",on:{"click":function($event){return _vm.goToStep('GlobalSettings')}}},[(_vm.isSiteVersionInvisible || !_vm.isPublished)?_c('div',{staticClass:"offline"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/svg/icon-invisible-red.svg"),"alt":""}}),_c('span',{staticClass:"state"},[_vm._v("Hors ligne")])]):_c('div',{staticClass:"online"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/svg/icon-visible.svg"),"alt":""}}),_c('span',{staticClass:"state"},[_vm._v("En ligne")])])]):_vm._e()]),_c('div',{staticClass:"site-settings__header__cta"},[(_vm.displayLiveEditCtas)?_c('div',{staticClass:"site-settings__header__cta__icons"},[_c('img',{class:[
            'site-settings__header__cta__icon',
            {
              'site-settings__header__cta__icon--active':
                _vm.previewSize === _vm.previewSizeDesktop,
            } ],attrs:{"src":require("@/assets/svg/preview-desktop.svg"),"alt":""},on:{"click":function($event){_vm.previewSize = _vm.previewSizeDesktop}}}),_c('img',{class:[
            'site-settings__header__cta__icon',
            {
              'site-settings__header__cta__icon--active':
                _vm.previewSize === _vm.previewSizeTablet,
            } ],attrs:{"src":require("@/assets/svg/preview-tablet.svg"),"alt":""},on:{"click":function($event){_vm.previewSize = _vm.previewSizeTablet}}}),_c('img',{class:[
            'site-settings__header__cta__icon',
            {
              'site-settings__header__cta__icon--active':
                _vm.previewSize === _vm.previewSizeMobile,
            } ],attrs:{"src":require("@/assets/svg/preview-mobile.svg"),"alt":""},on:{"click":function($event){_vm.previewSize = _vm.previewSizeMobile}}})]):_vm._e(),(_vm.currentTab === 'LiveEdit')?_c('button',{staticClass:"site-settings__header__cta__button button button--negative button--x-small",on:{"click":_vm.save}},[_vm._v(" ENREGISTRER ")]):_vm._e(),(_vm.currentTab === 'LiveEdit')?_c('button',{staticClass:"site-settings__header__cta__button button button--x-small",on:{"click":_vm.publish}},[_vm._v(" PUBLIER ")]):_vm._e()])]),_c('div',{staticClass:"site-settings__body"},[(_vm.currentTab === 'GlobalSettings')?_c('GlobalSettings'):_vm._e(),(_vm.currentTab === 'LawFirm')?_c('LawFirm'):_vm._e(),(_vm.currentTab === 'SpecialityTab')?_c('SpecialityTab'):_vm._e(),(_vm.currentTab === 'Coworkers')?_c('Coworkers'):_vm._e(),(_vm.currentTab === 'DesignSettings')?_c('DesignSettings'):_vm._e(),(_vm.currentTab === 'Legals')?_c('Legals'):_vm._e(),(_vm.currentTab === 'LiveEdit')?_c('LiveEdit',{attrs:{"preview-size":_vm.previewSize}}):_vm._e(),_c('div',{staticClass:"site-settings__step-buttons-wrapper"},[(
          [
            'GlobalSettings',
            'SpecialityTab',
            'LawFirm',
            'Coworkers',
            'DesignSettings',
            'Legals' ].includes(_vm.currentTab)
        )?_c('button',{staticClass:"site-settings__next-step-button button button--icon",on:{"click":_vm.nextStep}},[_c('span',[_vm._v("Étape suivante")])]):_vm._e(),_c('a',{directives:[{name:"show",rawName:"v-show",value:(
          [
            'DesignSettings',
            'SpecialityTab',
            'LawFirm',
            'Coworkers',
            'DesignSettings',
            'Legals' ].includes(_vm.currentTab)
        ),expression:"\n          [\n            'DesignSettings',\n            'SpecialityTab',\n            'LawFirm',\n            'Coworkers',\n            'DesignSettings',\n            'Legals',\n          ].includes(currentTab)\n        "}],staticClass:"site-settings__previous-step-button link",on:{"click":_vm.previousStep}},[_c('span',[_vm._v("Retour à l'étape précédente")])])])],1),_vm._m(0),_c('transition',{attrs:{"name":"modal"}},[(_vm.showSaveModal)?_c('Modal',{attrs:{"icon":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',[_vm._v("Votre site est enregistré !")])]},proxy:true},{key:"body",fn:function(){return [_c('p',{staticClass:"text text--2 text--hint text--hint-light"},[_vm._v(" Mais attention il n’est pas encore en ligne. Pour le mettre en ligne, veuillez cliquer sur Publier. ")])]},proxy:true}],null,false,454350962)}):_vm._e()],1),_c('transition',{attrs:{"name":"modal"}},[(_vm.showPublishModal)?_c('Modal',{attrs:{"icon":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.isDraft)?_c('h3',{staticClass:"text--center"},[_vm._v(" Bravo ! Votre site est en cours de mise en ligne. Il sera accessible d’ici 24H à 48H. ")]):_c('h3',{staticClass:"text--center"},[_vm._v("Votre site a été mis à jour")])]},proxy:true}],null,false,1381681482)}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-settings__mobile-warning"},[_c('h1',[_vm._v(" Cette fonctionnalité n'est pour le moment pas disponible sur mobile ")])])}]

export { render, staticRenderFns }